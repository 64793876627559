import BaseModel from './BaseModel';
import AuthoritiesModel from './AuthoritiesModel';

class AuthorityGroups extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.uuid = data.uuid;
    this.name = data.name;
    this.authorities = data.authorities
      ? data.authorities.map((value) => new AuthoritiesModel(value))
      : [];
  }
}

export default AuthorityGroups;

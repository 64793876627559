import { extendObservable } from "mobx";

import API from "../services/SubChannelAPI";
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";

import SubChannelModel from "~/models/SubChannelModel";

class SubChannelStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, {
      subChannel: undefined,
      subChannels: [],
      subChannelsListSelect: [],
      staffs: [],
      loading: false,
      superAdmin: false,
      totalPages: 0,
      size: 10,
      filters: {},
      page: 0,
      sort: "name",
    });
  }

  reset() {
    this.subChannel = [];
    this.totalPages = 0;
    this.size = 10;
    this.number = 0;
    this.sort = "name";
    this.filters = {};
    this.loading = false;
  }

  /**Atualiza um novo registror*/
  async onChange(prop, value) {
    const _subChannel = this.subChannel
      ? this.subChannel
      : new SubChannelModel();
    switch (prop) {
      case "channel":
        _subChannel.channel = { uuid: value.value };
        break;
      default:
        _subChannel[prop] = value;
    }
    this.subChannel = new SubChannelModel(_subChannel);
  }

  /**Cria um novo registro */
  async onCreate() {
    this.loading = true;
    const data = JSON.stringify(this.subChannel);
    const response = await API.save(data);
    this.loading = false;
    if (!response.error) {
      this.subChannel = new SubChannelModel();
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        "Sub Canal criado com sucesso."
      );
    } else if (response.error === " C�digo j� existente!") {
      this.toastHelper.notify(STATUS_HELPER.ERROR, "Código já existente!");
    } else if (response.error === " Sub-canal � obrigat�rio") {
      this.toastHelper.notify(STATUS_HELPER.ERROR, "SubCanal é obrigatório!");
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }
    return response;
  }

  async setSort(sort) {
    this.sort = sort;
    await this.getList(this.size, this.page, this.sort);
  }

  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPage(numPage, size = 10, sort) {
    this.page = numPage;
    this.size = size;
    this.sort = "name";

    sort ? (this.sort = sort) : (this.sort = "name");

    await this.getList();
    return true;
  }

  /**Busca todos os registros */
  async getList(
    size = 10,
    page = this.page,
    sort = this.sort,
    filters = this.filters
  ) {
    this.loading = true;
    const response = await API.getList({
      size,
      page,
      sort,
      ...filters,
    });
    this.loading = false;
    if (response.error) return [];
    this.subChannels = response.content.map((usr) => new SubChannelModel(usr));
    this.totalPages = response.totalPages;
    this.page = response.number;
  }

  async getExpoxtList(
    size = 1000,
    page = this.page,
    sort = this.sort,
    filters = this.filters
  ) {
    const response = await API.getList({
      size,
      page,
      sort,
      ...filters,
    });

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loading = false;
      return [];
    }
    return response.content.map((usr) => new SubChannelModel(usr));
  }

  /**
   * Busca todas as informações de um registro.
   * @param  {string} userName
   */
  async get(userName) {
    this.loading = true;
    const response = await API.get(userName);
    this.loading = false;
    if (!response.error) {
      this.subChannel = new SubChannelModel(response);
      return this.subChannel;
    }
    this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**Busca um registro passando parametro. */
  async onUpdate() {
    this.loading = true;

    const data = JSON.stringify(this.subChannel);
    const response = await API.update(this.subChannel.uuid, data);
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        "Informações atualizadas com sucesso!"
      );
      this.subChannel = new SubChannelModel(response);
    } else if (response.error === " C�digo j� existente!") {
      this.toastHelper.notify(STATUS_HELPER.ERROR, "Código já existente!");
    } else if (response.error === " Sub-canal � obrigat�rio") {
      this.toastHelper.notify(STATUS_HELPER.ERROR, "SubCanal é obrigatório!");
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }
    this.loading = false;
    return response;
  }

  /**Deleta um registro */
  async delete(uuid) {
    this.loading = true;
    const response = await API.delete(uuid);
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        "Sub Canal excluído com sucesso!"
      );
      this.getList();
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  async setFilters(filters) {
    this.filters = filters;
    await this.getList(this.size, this.page, this.sort, filters);
  }

  setValueChannel(uuid) {
    if (uuid !== undefined) {
      this.subChannel.channel = { uuid: uuid };
    }
  }

  async getListSelect() {
    const response = await API.getList({
      size: 100,
      page: 0,
      sort: "name",
    });

    if (response.error) {
      this.subChannelsListSelect = [];
      return;
    }

    this.subChannelsListSelect = response.content.map((subChannel) => ({
      value: subChannel.uuid,
      label: subChannel.name,
    }));
  }
}
export default SubChannelStore;

import BaseModel from './BaseModel';

class AuthoritiesModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.uuid = data.uuid;
    this.name = data.name;
    this.key = data.key;
    this.authorities = data.authorities
      ? data.authorities.map((value) => new AuthoritiesModel(value))
      : [];
  }
}

export default AuthoritiesModel;

import BaseModel from "./BaseModel";

class NewsLetterModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.emailBody = data.emailBody;
    this.emailTitle = data.emailTitle;
    this.design = data.design;
    this.modified = data.modified;
    this.name = data.name;
    this.uuid = data.uuid;
  }
}

export default NewsLetterModel;

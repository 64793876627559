import { extendObservable } from 'mobx';

import API from '../services/OrderAPI';
import ToastHelper, { STATUS_HELPER } from '~/helpers/ToastHelper';

import OrderModel from '~/models/OrderModel';

class OrderStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, {
      order: undefined,
      orders: [],
      ordersCustomer: [],
      channelsListSelect: [],
      staffs: [],
      loading: true,
      superAdmin: false,
      totalPages: 0,
      totalPagesCustomer: 0,
      size: 10,
      sizeCustomer: 10,
      filters: {},
      page: 0,
      pageCustomer: 0,
      sort: 'created,desc',
    });
  }

  reset() {
    this.order = undefined;
    this.totalPages = 0;
    this.size = 10;
    this.number = 0;
    this.sort = 'created,desc';
    this.filters = {};
    this.loading = true;
  }

  /**Atualiza um novo registror*/
  async onChange(prop, value) {
    const _channel = this.order ? this.order : new OrderModel();
    switch (prop) {
      default:
        _channel[prop] = value;
    }
    this.order = new OrderModel(_channel);
  }

  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPage(numPage, size = 10, sort) {
    this.page = numPage;
    this.size = size;
    this.sort = 'created,desc';

    sort ? (this.sort = sort) : (this.sort = 'created,desc');

    await this.getList();
    return true;
  }

  /**Busca todos os registros */
  async getList(
    size = 10,
    page = this.page,
    sort = this.sort,
    filters = this.filters
  ) {
    this.loading = true;
    const response = await API.getList({
      size,
      page,
      sort,
      ...filters,
    });
    this.loading = false;
    if (response.error) return [];
    this.orders = response.content.map((usr) => new OrderModel(usr));
    this.totalPages = response.totalPages;
    this.page = response.number;
  }

  async getExpoxtList(
    size = 1000,
    page = this.page,
    sort = this.sort,
    filters = this.filters
  ) {
    const response = await API.getList({
      size,
      page,
      sort,
      ...filters,
    });

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loading = false;
      return [];
    }
    return response.content.map((usr) => new OrderModel(usr));
  }

  /**
   * Busca todas as informações de um registro.
   * @param  {string} uuid
   */
  async get(user_uuid, uuid) {
    this.loading = true;
    const response = await API.get(user_uuid, uuid);
    this.loading = false;
    if (!response.error) {
      this.order = new OrderModel(response);
      return this.order;
    }
    this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  async getByCustomer(uuid, size = 10, page = 0, sort = 'idOrder') {
    const response = await API.getByCustomer(uuid, {
      size,
      page,
      sort,
    });

    if (response.error) return [];
    this.ordersCustomer = response.content.map((usr) => new OrderModel(usr));
    this.totalPagesCustomer = response.totalPages;
    this.pageCustomer = response.number;
  }

  async getByCustomerExport(uuid, size = 1000) {
    const response = await API.getByCustomer(uuid, {
      size,
    });

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loading = false;
      return [];
    }
    return response.content.map((usr) => new OrderModel(usr));
  }

  async setFilters(page, filters) {
    this.filters = filters;
    await this.getList(this.size, page, this.sort, filters);
  }
}
export default OrderStore;

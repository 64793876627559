import { extendObservable } from 'mobx';
import UnitModel from '../models/UnitModel';
import UnitAPI from '../services/UnitAPI';
import ToastHelper, { STATUS_HELPER } from '~/helpers/ToastHelper';

class UnitStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, {
      unit: undefined,
      loading: false,
      superAdmin: false,
      units: [],
      totalPages: 0,
      unitListSelect: [],
      size: 20,
      page: 0,
      sort: 'code',
    });
  }

  reset() {
    this.unit = [];
    this.totalPages = 0;
    this.size = 20;
    this.number = 0;
    this.sort = 'created';
    this.loading = false;
  }

  /**Atualiza uma propriedade da nova unidade */
  async onChange(prop, value) {
    const _unit = this.unit ? this.unit : new UnitModel();
    switch (prop) {
      default:
        _unit[prop] = value;
    }
    this.unit = new UnitModel(_unit);
  }

  /**Busca todos os usuarios addmins */
  async getList(size = 15, page = this.page, sort = this.sort) {
    this.loading = true;
    const response = await UnitAPI.getList({
      size,
      page,
      sort,
    });
    this.loading = false;
    if (response.error) return [];
    this.units = response.content.map((unit) => new UnitModel(unit));
    this.totalPages = response.totalPages;
    this.page = response.number;
  }

  /**
   * Busca todas as informações da unidade.
   * @param  {string} userName
   */
  async get(userName) {
    this.loading = true;
    const response = await UnitAPI.get(userName);
    this.loading = false;
    if (!response.error) {
      this.unit = new UnitModel(response);
      return this.unit;
    }
    this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**Atualiza os dados da unidade */
  async onUpdate() {
    this.loading = true;
    this.unit.externalId = this.unit.code;

    const data = JSON.stringify(this.unit);
    const response = await UnitAPI.update(this.unit.uuid, data);
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        'Informações atualizadas com sucesso!'
      );
      this.unit = new UnitModel(response);
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  /**Cria uma nova unidade */
  async onCreate() {
    this.loading = true;
    this.unit.externalId = this.unit.code;
    const data = JSON.stringify(this.unit);
    const response = await UnitAPI.save(data);
    this.loading = false;
    if (!response.error) {
      this.unit = new UnitModel();
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        'Unidade criada com sucesso.'
      );
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**Deleta unidade */
  async delete(uuid) {
    this.loading = true;
    const response = await UnitAPI.delete(uuid);
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        'Unidade excluída com sucesso!'
      );
      this.getList();
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  async getUnitListSelect() {
    const response = await UnitAPI.getList({
      size: 100,
      page: 0,
      sort: 'code',
    });

    if (response.error) {
      this.unitListSelect = [];
      return;
    }

    this.unitListSelect = response.content.map((size) => ({
      value: size.uuid,
      label: size.code,
    }));
  }

  async setSort(sort) {
    this.sort = sort;
    await this.getList(this.size, this.page, this.sort);
  }

  async setPage(page) {
    await this.getList(this.size, page);
  }
}
export default UnitStore;

class VoucherModel {
  constructor(data = {}) {
    this['@class'] = 'br.com.stoom.kernel.model.persistence.Voucher';
    this.uuid = data.uuid;
    this.deleted = data.deleted;
    // this.currentUses = data.currentUses || 0;
    this.identifier = data.identifier;
  }
}

export default VoucherModel;

import BaseModel from "./BaseModel";
import ProductModel from "./ProductModel";
import FileModel from "./FileModel";
import MerchantModel from "./MerchantModel";

class CategoryModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;
    this.idCategory = data.idCategory;
    this.categoryName = data.categoryName;
    this.shortDescription = data.shortDescription;
    this.type = data.type;
    this.newType = data.type;
    this.metaTitle = data.metaTitle;
    this.metaKeywords = data.metaKeywords;
    this.metaCanonical = data.metaCanonical;
    this.metaDescription = data.metaDescription;
    this.ordering = data.ordering;
    this.slug = data.slug;
    this.redirect = data.redirect;
    this.menuDisplay = data.menuDisplay;
    this.homeDisplay = data.homeDisplay;
    this.parentCategory =
      data.parentCategory && new CategoryModel(data.parentCategory);
    this.childrenCategory =
      data.childrenCategory &&
      data.childrenCategory.map((cat) => new CategoryModel(cat));
    this.merchant = data.merchant && new MerchantModel(data.merchant);
    this.files = data.files
      ? data.files.map((file) => new FileModel(file))
      : [];
    this.products =
      data.products && data.products.map((mProd) => new ProductModel(mProd));

    // extendObservable(this, {
    //   menuDisplay: data.menuDisplay !== undefined ? data.menuDisplay : false,
    //   homeDisplay: data.homeDisplay !== undefined ? data.homeDisplay : false,
    //   productDetailsDisplay:
    //     data.productDetailsDisplay !== undefined
    //       ? data.productDetailsDisplay
    //       : false,
    // });
  }

  //Retorna um array com o arquivo do cliente, caso exista.
  get filesCategory() {
    return this.files.find(
      (file) => file.path && file.metaTags.some((tag) => tag === "category")
    );
  }

  get fileCategory() {
    return this.filesCategory;
  }

  get iconCategory() {
    return this.files.find(
      (file) =>
        file.path && file.metaTags.some((tag) => tag === "icon-category")
    );
  }

  get createdIn() {
    return this._created;
  }

  get modifiedIn() {
    return this._modified;
  }
}

export default CategoryModel;

import BaseAPI, { URLS } from './BaseAPI';

class BannersAPI {
  static async getList(params) {
    try {
      const response = await BaseAPI.get(`${URLS.BANNERS}/filter?`, params);
      return response.status === 200
        ? response.data
        : { error: 'Falha ao carregar a lista de banners' };
    } catch (e) {
      // console.log(e.message);
      return { error: 'Falha ao carregar a lista de banners' };
    }
  }

  static async save(data) {
    try {
      const response = await BaseAPI.post(URLS.BANNERS, data);
      return response.status === 201 || response.status === 200
        ? response.data
        : { error: 'Falha ao salvar banner' };
    } catch (e) {
      // console.log(e.message);
      return { error: 'Falha ao salvar banner' };
    }
  }

  static async update(uuid, data) {
    try {
      const response = await BaseAPI.put(`${URLS.BANNERS}/${uuid}`, data);
      return response.status === 200
        ? response.data
        : { error: 'Falha ao atualizar banner' };
    } catch (e) {
      // console.log(e.message);
      return { error: 'Falha ao atualizar banner' };
    }
  }

  static async get(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.BANNERS}/${uuid}`);
      return response.status === 200
        ? response.data
        : { error: 'Falha ao carregar banner' };
    } catch (e) {
      // console.log(e.message);
      return { error: 'Falha ao carregar banner' };
    }
  }

  static async getTags() {
    try {
      const response = await BaseAPI.get('manager/banners/tags');
      if (response.status === 200) return response.data;
      return { error: 'Erro inesperado ao buscar tags de banner' };
    } catch (e) {
      return { error: 'Falha ao listar tags de banner' };
    }
  }

  static async delete(uuid) {
    try {
      const response = await BaseAPI.delete(`${URLS.BANNERS}/${uuid}`);
      return response.status === 200 || response.status === 204
        ? response.data
        : { error: 'Falha ao deletar banner' };
    } catch (e) {
      // console.log(e.message);
      return { error: 'Falha ao deletar banner' };
    }
  }

  static async removeImage(uuid, imageUuid) {
    try {
      const response = await BaseAPI.delete(
        `${URLS.BANNERS}/${uuid}/image/${imageUuid}`
      );

      return response.status === 204 || response.status === 200
        ? response.data
        : { error: 'Falha ao deletar imagem do banner' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao deletar imagem do banner' };
    }
  }
}

export default BannersAPI;

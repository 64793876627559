import AccountModel from './AccountModel';
import UserModel from './UserModel';

class AdministratorModel extends AccountModel {
  constructor(data = {}) {
    super(data);
    //this['@class'] = "br.com.stoom.kernel.model.persistence.Administrator";
    this.idAdministrator = data.idAdministrator;
    this.modified = data.modified;
    this.name = data.name;
    this.active = data.active;
    this.uuid = data.uuid;
    this.user = new UserModel(data.user);
  }

  get isSuperAdmin() {
    return this.user.role === 'STOOM' || this.user.role === 'SUPERADMIN';
  }
}

export default AdministratorModel;

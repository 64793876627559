import BaseModel from './BaseModel';
import MerchantModel from './MerchantModel';

class PropertiesModel {
  constructor(data = {}) {
    if (!data) return;
    this.className = data.className;
    this.ordering = parseInt(data.ordering);
    this.filter = true;
    this.display = true;
    this.merchant = new MerchantModel(data.merchant);
    this.uuid = data.uuid;
    this.values = data.values
      ? data.values.map((props) => new ValuesModel(props))
      : [];
  }
}

class ValuesModel {
  constructor(data = {}) {
    if (!data) return;
    this['@class'] = 'br.com.stoom.kernel.model.persistence.ProductProperty';
    this.active = data.active;
    this.created = data.created;
    this.deleted = data.deleted;
    this.modified = data.modified;
    this.displayString = data.displayString;
    this.merchant = new MerchantModel(data.merchant);
    this.ordering = data.ordering;
    this.propertyValue = data.propertyValue;
    this.uuid = data.uuid;
  }
}

export default PropertiesModel;

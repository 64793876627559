import { extendObservable } from 'mobx';
import ToastHelper, { STATUS_HELPER } from '~/helpers/ToastHelper';
import BranchModel from '~/models/BranchModel';
import BranchesAPI from '../services/BranchesAPI';
import FileModel from '~/models/FileModel';
import UploadAPI from '../services/UploadAPI';

class BranchesStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();

    extendObservable(this, {
      branchesList: [],
      branchesListSelect: undefined,
      branch: undefined,
      categoryEdit: undefined,
      branchFile: undefined,
      totalPages: 0,
      size: 15,
      page: 0,
      sort: 'created,desc',
      filters: {},
      loading: false,
      isCategory: false,
      massImportResult: [],
    });
  }

  reset() {
    this.branchesList = [];
    this.branchesListSelect = [];
    this.branch = undefined;
    this.categoryEdit = undefined;
    this.totalPages = 0;
    this.size = 15;
    this.page = 0;
    this.sort = 'created,desc';
    this.loading = false;
    this.massImportResult = [];
  }

  initializeBranch() {
    this.branch = new BranchModel({
      isCategory: this.isCategory,
      active: !this.isCategory,
    });
  }

  async getBranchesListSelect() {
    const response = await BranchesAPI.getList({
      size: 100,
      page: 0,
      sort: 'created,desc',
      isCategory: true,
    });

    if (response.error) {
      this.branchesListSelect = [];
      return;
    }

    this.branchesListSelect = response.content.map((branch) => ({
      value: branch?.uuid,
      label: branch?.name,
    }));
  }

  async getList(
    size = 15,
    page = this.page,
    sort = this.sort,
    filters = this.filters
  ) {
    const params = {
      size,
      page,
      sort,
      active: filters?.active?.value,
      name: filters?.branch === '' ? null : filters.branch,
      state: filters?.state?.value,
      isCategory: filters?.isCategory ?? false,
      ordering: filters?.ordering === '' || !filters?.ordering ? null : parseInt(filters?.ordering),
    };

    this.loading = true;
    const response = await BranchesAPI.getList(params);
    this.loading = false;

    if (response.error) {
      this.branchesList = [];
      return;
    }

    this.branchesList = response.content.map(
      (branch) => new BranchModel(branch)
    );

    this.totalPages = response.totalPages;
    this.page = response.number;
    this.size = response.size;
  }

  async setPage(numPage, size = 15, sort) {
    this.page = numPage;
    this.size = size;
    this.sort = 'created,desc';

    sort ? (this.sort = sort) : (this.sort = 'created,desc');

    await this.getList();
    return true;
  }

  async setSort(sort) {
    this.sort = sort;
    await this.getList(this.size, this.page, sort);
  }

  async setFilters(filters) {
    this.filters = filters;
    await this.getList(this.size, 0, this.sort, filters);
  }

  async getFileData() {
    const response = await UploadAPI.uploadBranch(this.branchFile[0]);
    if (!response.error) {
      const image = new FileModel(response?.data);
      const updatedBranch = { ...this.branch, image };
      this.branch = updatedBranch;
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response?.error);
    }
  }

  async save() {
    this.loading = true;
    const response = await BranchesAPI.save({
      ...this.branch,
    });
    this.loading = false;

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      return response;
    }

    this.toastHelper.notify(STATUS_HELPER.SUCCESS, 'Salvo com sucesso!');
    return response;
  }

  async update(isEdit) {
    this.loading = true;
    const response = await BranchesAPI.update(isEdit ? this.categoryEdit : this.branch);
    this.loading = false;

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      return response;
    }

    this.loading = false;

    this.toastHelper.notify(STATUS_HELPER.SUCCESS, 'Atualizado com sucesso!');
    return response;
  }

  async get(uuid, isEdit) {
    this.loading = true;
    const response = await BranchesAPI.get(uuid);
    this.loading = false;
    this.branchFile = response.image
    if (isEdit) {
      this.categoryEdit = new BranchModel({
        ...response,
      });
    } else {

    this.branch = new BranchModel({
      ...response,
    });
    }
  }

  async delete(uuid) {
    this.loading = true;
    const response = await BranchesAPI.delete(uuid);
    this.loading = false;

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    } else {
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, 'Deletado com sucesso!');
    }

    return response;
  }

  async createByCSV(file) {
    this.loading = true;
    const response = await BranchesAPI.createByCSV(file);
    this.loading = false;

    if (response?.error) {
      this.toastHelper.notify(
        STATUS_HELPER.WARNING,
        'Algumas filiais não foram salvas com sucesso. Verifique a lista de erros e tente novamente.',
        10000,
      );
      this.massImportResult = response.error;
    } else {
      this.massImportResult = response
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, 'Filiais salvas com sucesso!');
    }
  }

  async updateByCSV(file) {
    this.loading = true;
    const response = await BranchesAPI.updateByCSV(file);
    this.loading = false;

    if (response?.error) {
      this.toastHelper.notify(
        STATUS_HELPER.WARNING,
        'Algumas filiais não foram atualizadas com sucesso. Verifique a lista de erros e tente novamente.',
        10000,
      );
      this.massImportResult = response.error;
    } else {
      this.massImportResult = response
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, 'Filiais atualizadas com sucesso!');
    }
  }

  onChange(prop, value, isEdit) {
    switch (prop) {
      case 'file':
        this.branchFile = value;
        break;
      case 'state':
        this.branch[prop] = value.value;
        break;
      case 'startHourMonFri':
      case 'endHourMonFri':
      case 'startHourSat':
      case 'endHourSat':
      case 'startHourSun':
      case 'endHourSun':
      case 'startHourHol':
      case 'endHourHol':
        if (value && value !== '') {
          this.branch[prop] = value.concat(':00');
        } else {
          this.branch[prop] = undefined;
        }
        break;
      case 'activeEdit':
        this.categoryEdit['active'] = value;
        break;
      case 'isVisibleEdit':
        this.categoryEdit['isVisibleOnNavBar'] = value;
        break;
      case 'zipcode':
        this.branch[prop] = value.replace('-', '');
        break;
      default:
        if (isEdit) {
          this.categoryEdit[prop] = value;
        } else {
          this.branch[prop] = value;
        }
    }
  }
}

export default BranchesStore;

import BaseAPI, { URLS } from './BaseAPI';

class PropertiesAPI {
  static async getList(params) {
    try {
      const response = await BaseAPI.get(URLS.PROPERTIES, params);
      return response.status === 200
        ? response.data
        : { error: 'Falha ao carregar a lista de unidades' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao carregar a lista de unidades' };
    }
  }

  static async save(data) {
    try {
      const response = await BaseAPI.post(URLS.PROPERTIES, data);
      return response.status === 201
        ? response.data
        : { error: 'Falha ao salvar propriedade' };
    } catch (e) {
      return {
        error: e?.response?.data?.message
          ? e?.response?.data?.message
          : 'Falha ao salvar propriedade',
      };
    }
  }

  static async get(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.PROPERTIES}/${uuid}`);
      return response.status === 200
        ? response.data
        : { error: 'Falha ao carregar propriedade' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao carregar propriedade' };
    }
  }

  static async delete(uuid) {
    try {
      const response = await BaseAPI.delete(
        `/manager/properties/property/classes/${uuid}`
      );
      return response.status === 204
        ? response.data
        : { error: 'Falha ao deletar propriedade' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao deletar propriedade' };
    }
  }

  static async update(uuid, data) {
    try {
      const response = await BaseAPI.put(`${URLS.PROPERTIES}/${uuid}`, data);
      return response.status === 200
        ? response.data
        : { error: 'Falha ao atualizar tamanho' };
    } catch (e) {
      return {
        error: e?.response?.data?.message
          ? e?.response?.data?.message
          : 'Falha ao salvar tamanho',
      };
    }
  }

  static async saveProperties(uuid, data) {
    try {
      const response = await BaseAPI.post(
        `${URLS.PROPERTIES}/${uuid}/values`,
        data
      );
      return response.status === 200
        ? response.data
        : { error: 'Falha ao atualizar tamanho' };
    } catch (e) {
      return {
        error: e?.response?.data?.message
          ? e?.response?.data?.message
          : 'Falha ao salvar tamanho',
      };
    }
  }
}

export default PropertiesAPI;

import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import LoadingComponent from '../src/components/LoadingComponent/LoadingComponent';
import StorageUtil, { KEYS } from './helpers/utils/StorageUtil';

//Imports apenas importados caso forem requisitados pela aplicação.
const HomePage = lazy(() => import('./pages/HomePage/HomePage'));
const LoginCenterPage = lazy(() =>
  import('./pages/LoginCenterPage/LoginCenterPage')
);

// Clientes
const ManagerCustomerContainer = lazy(() =>
  import('~/containers/ManagerCustomerContainer')
);

const ManagerUserContainer = lazy(() =>
  import('~/containers/ManagerUserContainer')
);

const CustomerContainer = lazy(() => import('~/containers/CustomerContainer'));

// // Perfil
const ManagerPerfilContainer = lazy(() =>
  import('~/containers/ManagerPerfilContainer')
);

const UserContainer = lazy(() => import('~/containers/UserContainer'));
const PerfilContainer = lazy(() => import('~/containers/PerfilContainer'));

// // Filiais
const ManagerBranchesContainer = lazy(() =>
  import('~/containers/ManagerBranchesContainer')
);

const BranchesContainer = lazy(() => import('~/containers/BranchesContainer'));

// Inclusão e Edição de Filiais em Massa
const ManagerBranchesMassInclusionContainer = lazy(() =>
  import('~/containers/ManagerBranchesMassInclusionContainer')
);

const ManagerBranchesMassUpdateContainer = lazy(() =>
  import('~/containers/ManagerBranchesMassUpdateContainer')
);

// Home Dinamica
const ManagerDynamicHomeContainer = lazy(() =>
  import('~/containers/ManagerDynamicHomeContainer')
);

const DynamicHomeContainer = lazy(() =>
  import('~/containers/DynamicHomeContainer')
);

// Banner
const ManagerBannersContainer = lazy(() =>
  import('~/containers/ManagerBannersContainer')
);

const BannerContainer = lazy(() => import('~/containers/BannerContainer'));

// Categorias
const ManagerCategoryContainer = lazy(() =>
  import('~/containers/ManagerCategoryContainer')
);
const CategoryContainer = lazy(() => import('~/containers/CategoryContainer'));

const CategoryPageContainer = lazy(() => import('~/containers/CategoryPageContainer'));

//Pedidos
const ManagerOrderContainer = lazy(() =>
  import('~/containers/ManagerOrderContainer')
);

const ViewOrderContainer = lazy(() =>
  import('~/containers/OrderContainer/ViewOrderContainer')
);

// //Produtos
const ManagerProductCommentsContainer = lazy(() =>
  import('~/containers/ManagerProductCommentsContainer')
);

const ManagerProductsContainer = lazy(() =>
  import('~/containers/ManagerProductsContainer')
);

const ManagerProductBannersContainer = lazy(() =>
  import('~/containers/ManagerProductBannersContainer')
);

const ProductBannerContainer = lazy(() =>
  import('~/containers/ProductBannerContainer')
);

const ProductVariationContainer = lazy(() =>
  import('~/containers/ProductVariationContainer')
);

const ProductContainer = lazy(() => import('~/containers/ProductContainer'));

//Atributos
const ManagerColorContainer = lazy(() =>
  import('~/containers/ManagerColorContainer')
);
const ColorContainer = lazy(() => import('~/containers/ColorContainer'));

const ManagerSizeContainer = lazy(() =>
  import('~/containers/ManagerSizeContainer')
);
const SizeContainer = lazy(() => import('~/containers/SizeContainer'));

//Unidades
const UnitContainer = lazy(() => import('~/containers/UnitContainer'));
const ManagerUnitsContainer = lazy(() =>
  import('~/containers/ManagerUnitsContainer')
);

// //Campanha
const ManagerCampaignContainer = lazy(() =>
  import('~/containers/ManagerCampaignContainer')
);

const CampaignContainer = lazy(() => import('~/containers/CampaignContainer'));

//Merchant
const MerchantContainer = lazy(() => import('~/containers/MerchantContainer'));

const ManagerMerchantContainer = lazy(() =>
  import('~/containers/ManagerMerchantContainer')
);

//Relatorios
const ManagerSalesContainer = lazy(() =>
  import('~/containers/ManagerSalesContainer')
);

const ManagerReportProductContainer = lazy(() =>
  import('~/containers/ManagerReportProductContainer')
);

// //Newlstter
const ManagerNewsLetterContainer = lazy(() =>
  import('~/containers/ManagerNewsLetterContainer')
);

const NewsLetterContainer = lazy(() =>
  import('~/containers/NewsLetterContainer')
);

// //Cupom
const ManagerCouponContainer = lazy(() =>
  import('~/containers/ManagerCouponContainer')
);

const CouponContainer = lazy(() => import('~/containers/CouponContainer'));

// //Trasportadora
const ManagerShippingClassContainer = lazy(() =>
  import('~/containers/ManagerShippingClassContainer')
);

const ShippingClassContainer = lazy(() =>
  import('~/containers/ShippingClassContainer')
);

// //ZipCode
const ZipCodeRangeContainer = lazy(() =>
  import('~/containers/ZipCodeRangeContainer')
);

const ManagerZipCodeRangeContainer = lazy(() =>
  import('~/containers/ManagerZipCodeRangerContainer')
);

//Properties
const ManagerPropertiesContainer = lazy(() =>
  import('~/containers/ManagerPropertiesContainer')
);
const PropertiesContainer = lazy(() =>
  import('~/containers/PropertiesContainer')
);

//Rotas globais de nossa aplicação manager.
export default inject(({ authStore }) => ({ authStore }))(
  withRouter(
    observer((props) => {
      //função logout
      const logout = () => {
        props.authStore.logout().then(() => props.history.push('/'));
      };

      return (
        <Suspense fallback={<LoadingComponent />}>
          <Switch>
            <Route exact path="/login" render={() => <Redirect to={'/'} />} />
            <Route exact path="/logout" render={() => logout()} />
            <PrivateRoute
              path="/"
              component={HomePage}
              isAuthenticated={props.authStore.isAuthenticated}
            />
            <Redirect to="/error-page" />
          </Switch>
        </Suspense>
      );
    })
  )
);

export const HomeRoutes = () => (
  <Switch>
    {/* Clientes - */}
    <Route exact path="/clientes" component={ManagerCustomerContainer} />
    <Route
      exact
      path="/cadastrar-clientes-novo"
      component={CustomerContainer}
    />
    <Route
      exact
      path="/editar-cliente/:uuidCustomer"
      component={CustomerContainer}
    />

    {/* Filiais */}
    <Route exact path="/cadastrar-filial" component={BranchesContainer} />
    <Route
      exact
      path="/editar-filial/:uuidBranch"
      component={BranchesContainer}
    />
    <Route exact path="/filiais" component={ManagerBranchesContainer} />

    <Route exact path="/inclusao-filiais-em-massa" component={ManagerBranchesMassInclusionContainer} />

    <Route exact path="/edicao-filiais-em-massa" component={ManagerBranchesMassUpdateContainer} />

    {/* Banners */}
    <Route exact path="/cadastrar-banner" component={BannerContainer} />
    <Route
      exact
      path="/editar-banner/:bannerUuid"
      component={BannerContainer}
    />
    <Route exact path="/banners" component={ManagerBannersContainer} />

    {/* Merchant - */}
    <Route exact path="/cadastrar-merchant" component={MerchantContainer} />
    <Route exact path="/merchants" component={ManagerMerchantContainer} />
    <Route
      exact
      path="/editar-merchant/:uuidMerchant"
      component={MerchantContainer}
    />

    {/* Unidades - */}
    <Route exact path="/unidades" component={ManagerUnitsContainer} />
    <Route exact path="/cadastrar-unidade" component={UnitContainer} />
    <Route exact path="/editar-unidade/:uuidUnit" component={UnitContainer} />

    {/* Relatorios - */}
    <Route exact path="/vendas/general" component={ManagerSalesContainer} />
    <Route
      exact
      path="/vendas/produto"
      component={ManagerReportProductContainer}
    />

    {/* Perfil */}
    <Route path="/lista-perfil" component={ManagerPerfilContainer} />
    <Route path="/novo-perfil" component={PerfilContainer} />
    <Route path="/editar-perfil/:perfilUuid" component={PerfilContainer} />

    {/* Usuário */}
    <Route exact path="/lista-usuarios" component={ManagerUserContainer} />
    <Route exact path="/editar-usuario/:uuidUnit" component={UserContainer} />
    <Route exact path="/cadastrar-usuario" component={UserContainer} />

    {/* Atributos */}
    <Route exact path="/cores" component={ManagerColorContainer} />
    <Route exact path="/cadastrar-cor" component={ColorContainer} />
    <Route exact path="/editar-cor/:uuidColor" component={ColorContainer} />

    <Route exact path="/tamanhos" component={ManagerSizeContainer} />
    <Route exact path="/cadastrar-tamanho" component={SizeContainer} />
    <Route exact path="/editar-tamanho/:uuidSize" component={SizeContainer} />

    {/* Categorias */}
    <Route exact path="/categorias" component={ManagerCategoryContainer} />
    <Route exact path="/cadastrar-categoria" component={CategoryPageContainer} />
    <Route
      exact
      path="/editar-categoria/:uuidCategory"
      component={CategoryContainer}
    />

    {/* Propriedades - */}
    <Route exact path="/propriedades" component={ManagerPropertiesContainer} />
    <Route
      exact
      path="/cadastrar-propriedade"
      component={PropertiesContainer}
    />
    <Route
      exact
      path="/editar-propriedade/:uuidProperties"
      component={PropertiesContainer}
    />

    {/* Transportadora - */}
    <Route
      exact
      path="/transportadoras"
      component={ManagerShippingClassContainer}
    />
    <Route
      exact
      path="/cadastrar-transportadora"
      component={ShippingClassContainer}
    />
    <Route
      exact
      path="/editar-transportadora/:uuidShipping"
      component={ShippingClassContainer}
    />

    {/* Pedidos */}
    <Route exact path="/pedidos" component={ManagerOrderContainer} />

    <Route
      exact
      path="/detalhes-pedido/:uuidOrder/user/:uuidUser"
      component={ViewOrderContainer}
    />

    {/* Produtos */}
    <Route exact path="/produtos" component={ManagerProductBannersContainer} />
    <Route
      exact
      path="/gerenciar-comentarios"
      component={ManagerProductCommentsContainer}
    />

    <Route exact path="/cadastrar-produtos" component={ProductBannerContainer} />
    <Route
      exact
      path="/editar-produto/:uuidProduct"
      component={ProductBannerContainer}
    />
    <Route
      exact
      path="/cadastrar-variacao-produtos"
      component={ProductVariationContainer}
    />

    {/* ZipCode - */}
    <Route
      exact
      path="/faixas-frete"
      component={ManagerZipCodeRangeContainer}
    />
    <Route
      exact
      path="/cadastrar-faixa-frete"
      component={ZipCodeRangeContainer}
    />
    <Route
      exact
      path="/editar-faixa-frete/:uuidZipCodeRange"
      component={ZipCodeRangeContainer}
    />

    {/* Cupom - */}
    <Route exact path="/cupons" component={ManagerCouponContainer} />
    <Route exact path="/cadastrar-cupom" component={CouponContainer} />
    <Route exact path="/editar-cupom/:couponUuid" component={CouponContainer} />

    {/* Home Dinamica */}
    <Route
      exact
      path="/pagina-categorias"
      component={ManagerDynamicHomeContainer}
    />
    <Route
      exact
      path="/editar-pagina-categorias/:branchUuid"
      component={DynamicHomeContainer}
    />

    {/* Campanhas - */}
    <Route exact path="/campanhas" component={ManagerCampaignContainer} />
    <Route exact path="/cadastrar-campanha" component={CampaignContainer} />
    <Route
      exact
      path="/editar-campanha/:campaignUuid"
      component={CampaignContainer}
    />

    {/* Newsletters - */}
    <Route exact path="/newsletters" component={ManagerNewsLetterContainer} />
    <Route path="/newsletter" component={NewsLetterContainer} />
    <Route
      path="/editar-newsletter/:newsletterUuid"
      component={NewsLetterContainer}
    />
  </Switch>
);

/*Private route é responsável por verificar se o usuário está logado ou não. */
const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => {
  const token = StorageUtil.getItem(KEYS.TOKEN_KEY);

  return (
    <Route
      {...rest}
      render={(props) =>
        token ? <Component {...props} /> : <LoginCenterPage {...props} />
      }
    />
  );
};

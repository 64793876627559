import BaseModel from './BaseModel';
import ProductModel from './ProductModel';
import OrderModel from './OrderModel';
import ShippingDetailsModel from './ShippingDetailsModel';

class OrderItemModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.product = data.product ? new ProductModel(data.product) : undefined;
    this.order = data.order && new OrderModel(data.order);
    this.amount = data.amount;
    this.unitPrice = data.unitPrice;
    this.observation = data.observation || '';
    this.shipment = data.shipment;
    this.shippingAddress = data.shippingAddress;
    this.shippingDetails =
      data.shippingDetails && new ShippingDetailsModel(data.shippingDetails);
  }
}

export default OrderItemModel;

import BaseModel from './BaseModel';

class LoginModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;
    this.username = data.username;
    this.password = data.password;
    this.code = data.code;
  }
}

export default LoginModel;

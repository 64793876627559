import axios from 'axios';
import BaseAPI, { URLS, baseURL } from './BaseAPI';
import StorageUtil, { KEYS } from '~/helpers/utils/StorageUtil';

class BranchesAPI {
  static async getList(params) {
    try {
      const response = await BaseAPI.get(
        'manager/branches/filter?',
        params
      );
      return response.status === 200
        ? response.data
        : { error: 'Falha ao carregar a lista de filiais' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao carregar a lista de filiais' };
    }
  }

  static async save(data) {
    try {
      const response = await BaseAPI.post('manager/branches/create', data);
      return (response.status === 200 || response.status === 201)
        ? response.data
        : { error: 'Falha ao salvar filial' };
    } catch (e) {
      return {
        error: e?.response?.data?.message
          ? e?.response?.data?.message
          : 'Falha ao salvar filial',
      };
    }
  }

  static async update(data) {
    try {
      const response = await BaseAPI.put('manager/branches/update', data);
      return response.status === 200
        ? response.data
        : { error: 'Falha ao atualizar filial' };
    } catch (e) {
      return {
        error: e?.response?.data?.message
          ? e?.response?.data?.message
          : 'Falha ao salvar filial',
      };
    }
  }

  static async get(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.BRANCHES}/${uuid}`);
      return response.status === 200
        ? response.data
        : { error: 'Falha ao carregar filial' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao carregar filial' };
    }
  }

  static async delete(uuid) {
    try {
      const response = await BaseAPI.delete(`${URLS.BRANCHES}/${uuid}`);
      if (response.status === 204) return response.data;
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao deletar marca' };
    }
  }

  static async createByCSV(file) {
    try {
      const url = `${URLS.BRANCHES}/create-by-csv`;
      const form = new FormData();
      form.append('file', file);
      const data = form;
      const config = getConfig(file);
      const response = await BaseAPI.post(url, data, config);
      if (response.status === 200) return response.data;
    } catch (e) {
      console.log(e.message);
      return { error: e?.response?.data };
    }
  }

  static async updateByCSV(file) {
    try {
      const url = `${URLS.BRANCHES}/update-by-csv`;
      const form = new FormData();
      form.append('file', file);
      const data = form;
      const config = getConfig(file);
      const response = await BaseAPI.put(url, data, config);
      if (response.status === 200) return response.data;
    } catch (e) {
      console.log(e.message);
      return { error: e?.response?.data };
    }
  }
}

const getConfig = (uploadItem) => ({
  withCredentials: true,
  headers: {
    Authorization: `Bearer ${StorageUtil.getItem(KEYS.TOKEN_KEY)}`,
    'Content-Type': 'multipart/form-data',
  },
  onUploadProgress: (progressEvent) =>
  (uploadItem.percentCompleted = parseInt(
    Math.floor((progressEvent.loaded * 100) / progressEvent.total)
  )),
});

export default BranchesAPI;

import BaseModel from './BaseModel';
import MerchantModel from './MerchantModel';
//import ProductPropertyModel from './ProductPropertyModel';

class PropertiesClassModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    // this['@class'] = 'br.com.stoom.kernel.model.persistence.ProductClass';
    this.className = data.className;
    this.ordering = data.ordering;
    this.filter = data.filter !== undefined ? data.filter : true;
    this.display = data.display !== undefined ? data.display : true;
    this.displayString = data.displayString;
    this.viewType = data.viewType;
    this.merchant = new MerchantModel(data.merchant);
  }

  get isValid() {
    if (!this.className || this.className.length < 3) return false;
    if (!this.displayString) return false;
    return true;
  }
}

export default PropertiesClassModel;

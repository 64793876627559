import { BannerTagEnum } from "./enums/BannerTagEnum";
import { ComponentHomeTypesEnum } from "./enums/ComponentHomeTypesEnum";

/**Status */
export const maritalStatus = [
  { id: "SOLTEIRO", value: "SOLTEIRO", label: "Solteiro(a)" },
  { id: "CASADO", value: "CASADO", label: "Casado(a)" },
  { id: "VIUVO", value: "VIUVO", label: "Viúvo(a)" },
  { id: "OUTRO", value: "OUTRO", label: "Outro" },
];

export const unityTypes = [
  { id: "UN", value: "UN", label: "UN" },
  { id: "CX", value: "CX", label: "CX" },
];

/**Sexos */
export const genders = [
  { id: "M", value: "MASCULINO", label: "Masculino" },
  { id: "F", value: "FEMININO", label: "Feminino" },
  { id: "O", value: "OUTROS", label: "Outros" },
];

/**Tipos de entidade */
export const entityTypes = [
  { id: "F", value: "FISICA", label: "Física" },
  { id: "J", value: "JURIDICA", label: "Jurídica" },
];

/**Status**/
export const statusTypes = [
  { id: "active", value: true, label: "Ativo" },
  { id: "inactive", value: false, label: "Inativo" },
];

/**Tipos de moeda */
export const currency = [{ id: "BRL", value: "BRL", label: "R$ - Real" }];

/**Tipos de categoria */
export const categoryTypes = [
  { value: "VITRINE", label: "Não" },
  { value: "DEPARTAMENTO", label: "Sim" },
];

/**Tipos de categoria */
export const comparativeTypes = [
  { id: "TEXT", value: "TEXT", label: "Texto" },
  { id: "BOOLEAN", value: "BOOLEAN", label: "Boolean" },
];

/**Tags para Banner */
export const tagsBanners = [
  { label: "Slideshow Principal", value: "SLIDESHOW_PRINCIPAL" }, // Imagens a vontade, pode criar a vontade
  // { label: 'Slideshow Principal Mini', value: 'SLIDESHOW' }, // Imagens a vontade, pode criar a vontade
  { label: "Imagens Home", value: "IMAGENS_HOME" }, // Regra Esp. abrir drop. selecionar a disposição da imagens com 1, ou mais...até 6 imagens
  // { label: 'Carrinho', value: 'CARRINHO' }, // Até 1 Imagem
  // { label: 'Vitrine (PLP)', value: 'VITRINE_PLP' }, // uma imagem, e após selecionar é preciso abrir o dropwdown de categoria, o label
  // { label: 'Checkout', value: 'CHECKOUT' }, // Até 1 Imagem
  { label: "Sub-header", value: "SUB_HEADER" }, // Até 1 imagem
  { label: "Banner Parceiros", value: "BANNER_PARCEIROS" }, // Imagens a vontade, pode criar a vontade
];

/**Tags para usar no filtro Banner */
export const tagsFilterBanners = [
  { label: "Todas", value: "all" },
  { label: "Slideshow Principal", value: "SLIDESHOW_PRINCIPAL" }, // Imagens a vontade, pode criar uma ativa um de cada (responsivo e não responsivo)
  // { label: 'Slideshow Principal Mini', value: 'SLIDESHOW' }, // Imagens a vontade, pode criar a vontade
  { label: "Imagens Home", value: "IMAGENS_HOME" }, // Até 6 imagens, pode criar umas um de cada (responsivo e não responsivo)
  // { label: 'Carrinho', value: 'CARRINHO' }, // Até 1 Imagem, pode criar uma ativa um de cada (responsivo e não responsivo)
  // { label: 'Vitrine (PLP)', value: 'VITRINE_PLP' }, // Uma imagem, tem que adicionar categoria, pode criar uma ativa um de cada categoria (responsivo e não responsivo)
  // { label: 'Checkout', value: 'CHECKOUT' }, // Até 1 Imagem, pode criar uma ativa um de cada (responsivo e não responsivo)
  { label: "Sub-header", value: "SUB_HEADER" }, // Até 1 imagem, pode criar uma ativa um de cada (responsivo e não responsivo)
];

/**Tipos de categoria com coleção*/
export const categoryTypesWithCollection = [
  { id: "VITRINE", value: "VITRINE", label: "Vitrine" },
  { id: "COLECAO", value: "COLECAO", label: "Coleção" },
];

export const tagsImageHome = [
  { label: "Banner 01", value: "BANNER_01" },
  { label: "Banner 02", value: "BANNER_02" },
];

export const confirmTypes = [
  { id: true, value: true, label: "Sim" },
  { id: false, value: false, label: "Não" },
];

export const statusFilterTypes = [
  { id: "all", value: "", label: "Todos" },
  { id: "ativo", value: true, label: "Ativo" },
  { id: "inativo", value: false, label: "Inativo" },
];

export const bannerTagsTypes = Object.keys(BannerTagEnum).map((item) => ({
  id: item,
  value: item,
  label: item,
}));

export const ComponentHomeTypes = [
  {
    id: ComponentHomeTypesEnum.BANNER_CAROUSEL,
    value: ComponentHomeTypesEnum.BANNER_CAROUSEL,
    label: "Imagem",
  },
  {
    id: ComponentHomeTypesEnum.PRODUCT_CAROUSEL,
    value: ComponentHomeTypesEnum.PRODUCT_CAROUSEL,
    label: "Carrosel",
  },
];

export const orderStatus = [
  { id: "all", value: "", label: "Todos" },
  { id: "2", value: "ANALYSIS", label: "Pedido Realizado" },
  { id: "4", value: "BILLED", label: "Pedido Faturado" },
  { id: "6", value: "SENT", label: "Pedido Enviado" },
  { id: "8", value: "DELIVERED", label: "Pedido Entregue" },
  { id: "10", value: "PAID", label: "Pedido Concluído" },
  // { id: '10', value: "CANCELED", label: 'Pedido Cancelado' },
];

/**Unidades federativas */
export const statesOptions = [
  { id: 'AC', value: 'AC', label: 'AC' },
  { id: 'AL', value: 'AL', label: 'AL' },
  { id: 'AP', value: 'AP', label: 'AP' },
  { id: 'AM', value: 'AM', label: 'AM' },
  { id: 'BA', value: 'BA', label: 'BA' },
  { id: 'CE', value: 'CE', label: 'CE' },
  { id: 'DF', value: 'DF', label: 'DF' },
  { id: 'ES', value: 'ES', label: 'ES' },
  { id: 'GO', value: 'GO', label: 'GO' },
  { id: 'MA', value: 'MA', label: 'MA' },
  { id: 'MT', value: 'MT', label: 'MT' },
  { id: 'MS', value: 'MS', label: 'MS' },
  { id: 'MG', value: 'MG', label: 'MG' },
  { id: 'PA', value: 'PA', label: 'PA' },
  { id: 'PB', value: 'PB', label: 'PB' },
  { id: 'PR', value: 'PR', label: 'PR' },
  { id: 'PE', value: 'PE', label: 'PE' },
  { id: 'PI', value: 'PI', label: 'PI' },
  { id: 'RJ', value: 'RJ', label: 'RJ' },
  { id: 'RN', value: 'RN', label: 'RN' },
  { id: 'RS', value: 'RS', label: 'RS' },
  { id: 'RO', value: 'RO', label: 'RO' },
  { id: 'RR', value: 'RR', label: 'RR' },
  { id: 'SC', value: 'SC', label: 'SC' },
  { id: 'SP', value: 'SP', label: 'SP' },
  { id: 'SE', value: 'SE', label: 'SE' },
  { id: 'TO', value: 'TO', label: 'TO' }
];
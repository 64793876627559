export const ModalIdsEnum = Object.freeze({
  DELETE_NEWSLETTER: "delete-newsletter-modal",
  IS_ALL_PRODUCTS_CAMPAIGN: "IS_ALL_PRODUCTS_CAMPAIGN",
  DISABLE_VARIATION: "DISABLE_VARIATION",
  UNLINK_VARIATION: "UNLINK_VARIATION",
  DELETE_SHIPPING_COMPANY: "delete-shipping-company-modal",
});

export const ProductTypeEnum = {
  MODEL: "MODEL",
  VARIATION: "VARIATION",
  PART: "PART",
};

export class RestrictionsModel {
  constructor(data = {}) {
    this.uuid = data.uuid;
    this.deleted = data.deleted;
  }
}

export class ProductCategoryRestriction extends RestrictionsModel {
  constructor(data = {}) {
    super(data);
    this["@class"] =
      "br.com.stoom.kernel.model.persistence.restriction.ProductCategoryRestriction";
    this.categories = data.categories ? data.categories : [];
    this.products = data.products;
  }
}

export class DateRestriction extends RestrictionsModel {
  constructor(data = {}) {
    super(data);
    this["@class"] =
      "br.com.stoom.kernel.model.persistence.restriction.DateRestriction";
    this.minDate = data.minDate ? new Date(data.minDate) : undefined;
    this.maxDate = data.maxDate ? new Date(data.maxDate) : undefined;
  }
}

export class CustomerRestriction extends RestrictionsModel {
  constructor(data = {}) {
    super(data);
    this["@class"] =
      "br.com.stoom.kernel.model.persistence.restriction.CustomerRestriction";
    this.emails = data.emails;
    this.documents = data.documents || [];
  }
}

export class FirstPurchaseRestriction extends RestrictionsModel {
  constructor(data = {}) {
    super(data);
    this["@class"] =
      "br.com.stoom.kernel.model.persistence.restriction.FirstPurchaseRestriction";
    this.excludes = data.excludes;
  }
}

export class PriceRestriction extends RestrictionsModel {
  constructor(data = {}) {
    super(data);
    this["@class"] =
      "br.com.stoom.kernel.model.persistence.restriction.PriceRestriction";
    this.minPrice = data.minPrice;
    this.maxPrice = data.maxPrice;
  }
}

export class BuyXProductRestriction extends RestrictionsModel {
  constructor(data = {}) {
    super(data);
    this["@class"] =
      "br.com.stoom.kernel.model.persistence.restriction.BuyXProductRestriction";
    this.amount = data.amount;
  }
}

export class CustomerGroupRestriction extends RestrictionsModel {
  constructor(data = {}) {
    super(data);
    this["@class"] =
      "br.com.stoom.kernel.model.persistence.restriction.GroupRestriction";
    this.customerGroups = data.customerGroups || [];
  }
}

export class ZipCodeRestriction extends RestrictionsModel {
  constructor(data = {}) {
    super(data);
    this["@class"] =
      "br.com.stoom.kernel.model.persistence.restriction.ZipCodeRestriction";
    this.initialZipCode = data.initialZipCode;
    this.finalZipCode = data.finalZipCode;
  }
}

export class SubChannelRestriction extends RestrictionsModel {
  constructor(data = {}) {
    super(data);
    this["@class"] =
      "br.com.stoom.tresCoracoes.model.TresCoracoesChannelRestriction";
    this.subChannels = data.subChannels || [];
    this.channels = data.channels || [];
  }
}

export class PointOfSaleRestriction extends RestrictionsModel {
  constructor(data = {}) {
    super(data);
    this["@class"] =
      "br.com.stoom.kernel.model.persistence.restriction.PointOfSaleRestriction";
    this.pointsOfSale = data.pointsOfSale;
  }
}

export class AvenidaDeliveryRestriction extends RestrictionsModel {
  constructor(data = {}) {
    super(data);
    this["@class"] =
      "br.com.stoom.kernel.model.persistence.restriction.DeliveryRestriction";
    this.optionTypes = data.optionTypes;
  }
}

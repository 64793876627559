import { extendObservable } from "mobx";
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";
import DynamicHomeAPI from "../services/DynamicHomeAPI";
import DynamicHomeModel from "~/models/DynamicHomeModel";
import { ComponentHomeTypesEnum } from "~/helpers/utils/enums/ComponentHomeTypesEnum";

class DynamicHomeStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();

    extendObservable(this, {
      dynamicHomeList: [],
      dynamicHome: new DynamicHomeModel(),
      totalPages: 0,
      size: 20,
      page: 0,
      sort: "created",
      filters: {},
      loading: false,
      loadingComponent: false,
    });
  }

  reset() {
    this.dynamicHomeList = [];
    this.dynamicHome = new DynamicHomeModel();
    this.totalPages = 0;
    this.size = 20;
    this.page = 0;
    this.sort = "created";
    this.loading = false;
  }

  initializeDynamicHome(branch_uuid) {
    this.dynamicHome = new DynamicHomeModel({
      branch: { uuid: branch_uuid },
    });
  }

  async getList(
    size = 20,
    page = this.page,
    sort = this.sort,
    filters = this.filters
  ) {
    this.loading = true;

    const response = await DynamicHomeAPI.getList({
      ...filters,
    });

    this.loading = false;

    if (response.error) {
      this.dynamicHomeList = [];
      return;
    }

    this.dynamicHomeList = response.content.map(
      (banner) => new DynamicHomeModel(banner)
    );
    this.totalPages = response.totalPages;
    this.page = response.number;
    this.size = response.size;
  }

  async setPage(page) {
    await this.getList(this.size, page);
  }

  async setSort(sort) {
    this.sort = sort;
    await this.getList(this.size, this.page, sort);
  }

  async setFilters(filters) {
    this.filters = filters;
    await this.getList(this.size, this.page, this.sort, filters);
  }

  async save() {
    this.loading = true;
    const response = await DynamicHomeAPI.save(this.dynamicHome);
    this.loading = false;

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    } else {
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, "Salvo com sucesso!");
    }
    return response;
  }

  async delete(uuid) {
    this.loading = true;
    const response = await DynamicHomeAPI.delete(uuid);
    this.loading = false;

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    } else {
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, "Deletado com sucesso!");
    }

    return response;
  }

  onChange(prop, value) {
    switch (prop) {
      case "branch_uuid":
        this.dynamicHome.branch = { uuid: value };
        break;
      case "mobile":
        this.dynamicHome[prop] = value?.value;
        break;
      case "type": {
        const temp = this.dynamicHome;
        temp[prop] = value?.value;

        if (value?.value === ComponentHomeTypesEnum.BANNER_CAROUSEL) {
          this.dynamicHome.category = undefined;
        } else if (value?.value === ComponentHomeTypesEnum.PRODUCT_CAROUSEL) {
          this.dynamicHome.banners = undefined;
        }

        this.dynamicHome = new DynamicHomeModel(temp);
        break;
      }
      case "banners":
        this.dynamicHome[prop] = [
          {
            uuid: value?.value,
          },
        ];
        break;
      case "category":
        this.dynamicHome[prop] = {
          uuid: value?.value,
        };
        break;
      default:
        this.dynamicHome[prop] = value;
    }
  }
}

export default DynamicHomeStore;

import { extendObservable } from 'mobx';

import AdministratorAPI from '../services/AdministratorAPI';
import ToastHelper, { STATUS_HELPER } from '~/helpers/ToastHelper';
import CustomersTypeModel from '~/models/CustomerTypeModel';
import CustomerAPI from '../services/CustomerAPI';

class CustomerTypeStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, {
      customerType: undefined,
      customerTypeA: undefined,
      customerTypeB: undefined,
      customerTypeC: undefined,
      customersType: [],
      loading: false,
    });
  }

  reset() {
    this.customersType = undefined;
    this.customersType = [];
  }

  getDefaultCustomerType() {
    const defaultCustomerType =
      this.rootStore.customerTypeStore.getCustomerType('F/J');

    return defaultCustomerType;
  }

  async getList() {
    this.loading = true;
    const response = await CustomerAPI.getAllCustomersType();
    if (response.error) return [];
    this.customersType = response.content.map(
      (usr) => new CustomersTypeModel(usr)
    );
    this.loading = false;
  }

  async get(uuid) {
    this.loading = true;
    const response = await AdministratorAPI.getUserAdmin(uuid);
    this.loading = false;
    if (!response.error) {
      this.customerType = new CustomersTypeModel(response);
      return this.customerType;
    }
    this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  getListSelect(administrators = this.administrators) {
    return administrators
      .filter((a) => !a.isSuperAdmin)
      .map((a) => ({ value: a.uuid, label: a.name }));
  }

  getCustomerType(customerType) {
    this.loading = true;
    let ct;
    /*  if(this.customersType === []){
             this.getList();
       } */
    ct = this.customersType.filter((ct) => ct.name === customerType)[0];
    this.loading = false;
    return ct;
  }
}
export default CustomerTypeStore;

import { extendObservable } from 'mobx';

import API from '../services/ChannelAPI';
import ToastHelper, { STATUS_HELPER } from '~/helpers/ToastHelper';

import ChannelModel from '~/models/ChannelModel';

class ChannelStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, {
      channel: undefined,
      channels: [],
      channelsListSelect: [],
      staffs: [],
      loading: false,
      superAdmin: false,
      totalPages: 0,
      size: 10,
      filters : {},
      page: 0,
      sort: 'name',
    });
  }

  reset() {
    this.channel = [];
    this.totalPages = 0;
    this.size = 10;
    this.number = 0;
    this.sort = 'name';
    this.filters = {};
    this.loading = false;
  }

  /**Atualiza um novo registror*/
  async onChange(prop, value) {
    const _channel = this.channel ? this.channel : new ChannelModel();
    switch (prop) {
      default:
        _channel[prop] = value;
    }
    this.channel = new ChannelModel(_channel);
  }

  /**Cria um novo registro */
  async onCreate() {
    this.loading = true;
    const data = JSON.stringify(this.channel);
    const response = await API.save(data);
    this.loading = false;
    if (!response.error) {
      this.channel = new ChannelModel();
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, 'Canal criado com sucesso.');
    } else {
      if (response.error === ' C�digo j� existente!') {
        this.toastHelper.notify(STATUS_HELPER.ERROR, 'Código já existente!');
      } else {
        this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      }
    }
    return response;
  }

  async setSort(sort) {
    this.sort = sort;
    await this.getList(this.size, this.page, this.sort);
  }
  
  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPage(numPage, size = 10, sort) {
    this.page = numPage;
    this.size = size;
    this.sort = 'name';

    sort ? (this.sort = sort) : (this.sort = 'name');

    await this.getList();
    return true;
  }

  /**Busca todos os registros */
  async getList(size = 10, page = this.page, sort = this.sort, filters = this.filters) {
    this.loading = true;
    const response = await API.getList({
      size,
      page,
      sort,
      ...filters
    });
    this.loading = false;
    if (response.error) return [];
    this.channels = response.content.map((usr) => new ChannelModel(usr));
    this.totalPages = response.totalPages;
    this.page = response.number;
  }


  async getExpoxtList(
    size = 1000,
    page = this.page,
    sort = this.sort,
    filters = this.filters,
  ) {

    const response = await API.getList({
      size,
      page,
      sort,
      ...filters,
    });

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loading = false;
      return [];
    }
      return response.content.map((usr) => new ChannelModel(usr));
  }

  /**
   * Busca todas as informações de um registro.
   * @param  {string} userName
   */
  async get(userName) {
    this.loading = true;
    const response = await API.get(userName);
    this.loading = false;
    if (!response.error) {
      this.channel = new ChannelModel(response);
      return this.channel;
    }
    this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**Busca um registro passando parametro. */
  async onUpdate() {
    this.loading = true;

    const data = JSON.stringify(this.channel);
    const response = await API.update(this.channel.uuid, data);
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        'Informações atualizadas com sucesso!'
      );
      this.channel = new ChannelModel(response);
    } else  if (response.error === ' C�digo j� existente!') {
      this.toastHelper.notify(STATUS_HELPER.ERROR, 'Código já existente!');
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }
    this.loading = false;
    return response;
  }

  async setFilters(filters) {
    this.filters = filters;
    await this.getList(this.size, this.page, this.sort, filters);
  }

  /**Deleta um registro */
  async delete(uuid) {
    this.loading = true;
    const response = await API.delete(uuid);
    if (!response.error) {
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, 'Canal excluído com sucesso!');
      this.getList();
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  async getItemListSelect() {
    const response = await API.getList({
      size: 100,
      page: 0,
      sort: 'name',
    });

    if (response.error) {
      this.channelsListSelect = [];
      return;
    }

    this.channelsListSelect = response.content.map((channel) => ({
      value: channel.uuid,
      label: channel.name,
    }));
  }
}
export default ChannelStore;

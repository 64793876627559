import { extendObservable } from "mobx";
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";
import { simpleDateTimeToText } from "~/helpers/utils/Functions";
import NewsLetterModel from "../models/NewsLetterModel";
import NewsletterAPI from "../services/NewsletterAPI";

const initValues = {
  loading: false,
  newsLetters: [],
  newsLetter: null,
  totalPages: 0,
  page: 0,
  size: 10,
  sort: "name",
};

class NewsLetterStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    extendObservable(this, { ...initValues });
    this.toastHelper = new ToastHelper();
  }

  resetState() {
    this.newsLetter = null;
    this.page = 0;
    this.size = 10;
    this.sort = "name";
  }

  /**
   * @param {string} uuid
   */
  async sendNewsLetter(uuid) {
    this.loading = true;

    const params = `newslettersUuid=${uuid}`;

    const response = await NewsletterAPI.send(params);

    const toastMessage = {
      error: [STATUS_HELPER.ERROR, response.error],
      success: [STATUS_HELPER.INFO, "Newsletter enviada"],
    };

    const [status, message] =
      toastMessage[response.error ? "error" : "success"];

    this.toastHelper.notify(status, message);

    this.loading = false;
    return response;
  }

  /**f
   * @param {Object} data
   */
  async saveNewsLetter(sendData) {
    this.loading = true;
    this.newsLetter.emailBody = sendData.html;
    this.newsLetter.design = sendData.designJson;
    const response = await NewsletterAPI.save(this.newsLetter);

    const toastMessage = {
      error: [STATUS_HELPER.ERROR, response.error],
      success: [STATUS_HELPER.INFO, "Newsletter salva"],
    };

    const [status, message] =
      toastMessage[response.error ? "error" : "success"];

    this.toastHelper.notify(status, message);

    this.loading = false;
    return response;
  }

  /**
   * @param {string} htmlData
   */
  async getNewsletters() {
    this.loading = true;

    const params = {
      page: this.page,
      size: this.size,
      sort: this.sort,
    };

    const response = await NewsletterAPI.get(params);

    if (response.error) {
      return this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }

    this.newsLetters = response.content.map((letter) => ({
      ...letter,
      created: simpleDateTimeToText(letter.created),
    }));

    this.page = response.number;
    this.totalPages = response.totalPages;

    this.loading = false;
    return response;
  }

  /**
   * @param {string} uuid
   */
  async getNewsLetterById(uuid) {
    this.loading = true;
    const response = await NewsletterAPI.getById(uuid);

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    } else {
      this.newsLetter = new NewsLetterModel(response);
      this.loading = false;
      return response;
    }
    this.loading = false;
    return response.error;
  }

  /**
   * @param {object} data
   * @param {string} uuid
   */
  async updateNewsLetter(sendData, uuid) {
    this.loading = true;
    this.newsLetter.emailBody = sendData.html;
    this.newsLetter.design = sendData.designJson;
    const response = await NewsletterAPI.update(this.newsLetter, uuid);

    const toastMessage = {
      error: [STATUS_HELPER.ERROR, response.error],
      success: [STATUS_HELPER.INFO, "Newsletter salva"],
    };

    const [status, message] =
      toastMessage[response.error ? "error" : "success"];

    this.toastHelper.notify(status, message);

    this.loading = false;
    return response;
  }

  /**
   * @param {string} uuid
   */
  async deleteNewsLetter(uuid) {
    this.loading = true;
    const response = await NewsletterAPI.delete(uuid);

    const toastMessage = {
      error: [STATUS_HELPER.ERROR, response.error],
      success: [STATUS_HELPER.INFO, "Deletado com sucesso "],
    };

    const [status, message] =
      toastMessage[response.error ? "error" : "success"];

    this.toastHelper.notify(status, message);

    this.loading = false;
    this.getNewsletters();
    return response;
  }

  /**Atualiza uma propriedade do novo adminstrator*/
  async onChange(prop, value) {
    const _newsLetter = this.newsLetter
      ? this.newsLetter
      : new NewsLetterModel();
    switch (prop) {
      default:
        _newsLetter[prop] = value;
    }
    this.newsLetter = new NewsLetterModel(_newsLetter);
  }
}

export default NewsLetterStore;

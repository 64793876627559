import ChannelModel from './ChannelModel';

class SubChannelModel {
  constructor(data = {}) {
    if (!data) return;
    this.uuid = data.uuid;
    this.name = data.name;
    this.code = data.code;
    this.active = data.active;
    this.deleted = data.deleted;
    this.channel = data.channel ? new ChannelModel(data.channel) : null;
  }
}

export default SubChannelModel;

export const BannerTagEnum = Object.freeze({
  SLIDESHOW_PRINCIPAL: "SLIDESHOW_PRINCIPAL",
  SLIDESHOW: "SLIDESHOW",
  IMAGENS_HOME: "IMAGENS_HOME",
  CARRINHO: "CARRINHO",
  VITRINE_PLP: "VITRINE_PLP",
  CHECKOUT: "CHECKOUT",
  SUB_HEADER: "SUB_HEADER",
  BLOG: "BLOG",
  MARCAS: "MARCAS",
  VITRINE_PLP_MARCA: "VITRINE_PLP_MARCA",
  VITRINE_PLP_CAMPANHA: "VITRINE_PLP_CAMPANHA",
  BANNER_PARCEIROS: "BANNER_PARCEIROS",
});



import BaseAPI, { URLS } from './BaseAPI';

class CustomerAPI {
  /**
   * Criação de um novo usuário, envio
   * @param  {object} userData Dados do novo usuário à ser criado
   * @returns uuid ou mensagem de erro
   */
  static async createUser(userData) {
    try {
      const response = await BaseAPI.post('/customers', userData);
      return response.status === 201
        ? response
        : { error: 'Falha ao cadastrar usuario' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao cadastrar usuário' };
    }
  }

  /**
   * Ativação de clientes inativos.
   * @param  {object} customer Dados do novo usuário que será ativado
   * @returns uuid ou mensagem de erro
   */
  static async activeClient(customer) {
    try {
      const url = `${URLS.CUSTOMERS}/active`;
      const response = await BaseAPI.put(url, customer);
      return response.status === 200
        ? response.data
        : { error: 'Falha ao ativar cliente' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao ativar cliente' };
    }
  }

  /**
   * @param  {string} uuid Uuid do usuário que será editado
   * @param {object} data dados do usuário que será editado
   */
  static async update(uuid, data) {
    try {
      const response = await BaseAPI.put(`${URLS.CUSTOMERS}/${uuid}`, data);
      if (response.status === 200) return response.data;
      return { error: 'Falha ao atualizar informações do usuário' };
    } catch (e) {
      console.log(e);
      return { error: 'Falha ao atualizar informações do usuário' };
    }
  }

  /**
   * Busca um usuario ( admin ou customer )
   * @param  {string} userName
   */
  static async get(prop, value) {
    try {
      const url = `${URLS.CUSTOMERS}/${value}/`;
      const params = { by: prop };
      const response = await BaseAPI.get(url, params);
      return response.data;
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao buscar usuário' };
    }
  }

  /**
   * @param  {string} uuid Uuid do usuário que será excluído
   */
  static async delete(uuid) {
    try {
      const response = await BaseAPI.delete(`${URLS.CUSTOMERS}/${uuid}`);
      if (response.status === 204) return true;
      return { error: 'Não foi possível excluir o administrador' };
    } catch (e) {
      console.log(e);
      return { error: e.message };
    }
  }

  /**Monta uma query de acordo aos dados  */
  /**
   * @param  {object} prop
   * @param  {valor} value
   */
  static async getBy(prop, value) {
    try {
      const url = `${URLS.INSUMOS_HOLAMBRA}/${value}/`;
      const params = { by: prop };
      const response = await BaseAPI.get(url, params);
      if (response.status === 200) return response.data;
      return { error: 'Falha ao buscar usuário' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao buscar usuário' };
    }
  }

  static async getUpdateErp(uuid) {
    try {
      const url = `customers/${uuid}/update-erp`;
      const response = await BaseAPI.get(url);
      if (response.status === 200) return response.data;
    } catch (e) {
      console.log(e.message);
    }
    return { error: 'Falha ao sincronizar com ERP' };
  }

  /**
   * Busca lista de clientes
   * @param  {params} params parametros de paginação
   */
  static async getList(params) {
    try {
      const response = await BaseAPI.get(`${URLS.ACCOUNTS}`, {
        ...params,
        type: 'customers',
      });
      if (response.status === 200) return response.data;
      return { error: 'Falha ao buscar usuários' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao buscar usuários' };
    }
  }

  /**
   * Busca lista de clientes pedentes
   * @param  {params} params parametros de paginação
   */
  static async getPending(params) {
    try {
      const url = `${URLS.CUSTOMERS}/pending`;
      const response = await BaseAPI.get(url, params);
      if (response.status === 200) return response.data;
      return { error: 'Falha ao buscar clientes pendentes' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao buscar usuários' };
    }
  }

  static async getAllCustomersType() {
    try {
      const url = `${URLS.CUSTOMERS}/types`;
      const response = await BaseAPI.get(url);
      if (response.status === 200) return response.data;
      return { error: 'Falha ao buscar tipos de cliente' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao buscar tipos de cliente' };
    }
  }
}

export default CustomerAPI;

import BaseAPI, { URLS } from './BaseAPI';

class OrderAPI {
  static async getList(params) {
    try {
      const response = await BaseAPI.get(
        `/manager/ordersmerchants/search`,
        params
      );
      return response.status === 200
        ? response.data
        : { error: 'Falha ao carregar a lista de pedidos' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao carregar a lista de pedidos' };
    }
  }

  static async save(data) {
    try {
      const response = await BaseAPI.post(URLS.ORDER, data);
      return response.status === 201
        ? response.data
        : { error: 'Falha ao salvar pedido' };
    } catch (e) {
      return {
        error: e?.response?.data?.message
          ? e?.response?.data?.message
          : 'Falha ao salvar pedido',
      };
    }
  }

  static async update(uuid, data) {
    try {
      const response = await BaseAPI.put(`${URLS.ORDER}/${uuid}`, data);
      return response.status === 200
        ? response.data
        : { error: 'Falha ao atualizar pedido' };
    } catch (e) {
      return {
        error: e?.response?.data?.message
          ? e?.response?.data?.message
          : 'Falha ao salvar pedido',
      };
    }
  }

  static async get(userUuid, orderUuid) {
    try {
      const response = await BaseAPI.get(
        `/manager/customers/${userUuid}/ordersmerchant/${orderUuid}`
      );
      return response.status === 200
        ? response.data
        : { error: 'Falha ao carregar pedido' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao carregar pedido' };
    }
  }

  static async getByCustomer(customerUuid, params) {
    try {
      const response = await BaseAPI.get(
        `${URLS.ORDER}/customers/${customerUuid}/`,
        params
      );

      return response.status === 200
        ? response.data
        : { error: 'Falha ao carregar pedido' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao carregar pedido' };
    }
  }

  static async delete(uuid) {
    try {
      const response = await BaseAPI.delete(`${URLS.ORDER}/${uuid}`);
      return response.status === 204
        ? response.data
        : { error: 'Falha ao deletar pedido' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao deletar pedido' };
    }
  }
}

export default OrderAPI;

import BaseAPI, { URLS } from "./BaseAPI";

class ZipCodeRangeAPI {
  /**Método util para retorno de erro */
  static _exception(e, message) {
    console.log(e);
    return { error: message };
  }

  /**
   * @param  {string} uuid - Uuid da faixa de cep à ser procurada
   */
  static async get(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.ZIPCODERANGE}/${uuid}`, {
        by: "uuid",
      });
      if (response.status === 200) return response.data;
      const error =
        response.status === 404
          ? "Faixa de cep não encontrado"
          : "Erro inexperado ao buscar Faixa de cep";
      return { error };
    } catch (e) {
      return this._exception(e, "Erro ao buscar faixa de cep");
    }
  }

  /**
   * @param  {string} uuid - Uuid da faixa de cep à ser deletada
   */
  static async delete(uuid) {
    try {
      const response = await BaseAPI.delete(`${URLS.ZIPCODERANGE}/${uuid}`);
      if (response.status === 204) return response.data;
      const error =
        response.status === 404
          ? "Faixa de cep não encontrado"
          : "Erro inexperado ao buscar Faixa de cep";
      return { error };
    } catch (e) {
      return this._exception(e, "Erro ao deletar Faixa de cep");
    }
  }

  /**
   * @param  {string} uuid - Uuid da faixa de cep à ser atualizada
   */
  static async update(uuid, data) {
    try {
      const response = await BaseAPI.put(`${URLS.ZIPCODERANGE}/${uuid}`, data);
      if (response.status === 200) return response.data;
      const error =
        response.status === 404
          ? "Faixa de cep não encontrado"
          : "Erro inexperado ao atualizar Faixa de cep";
      return { error };
    } catch (e) {
      return this._exception(e, "Erro ao atualizar dados da indústria");
    }
  }

  /**
   * @param  {object} data - dados da faixa de cep que será adicionada
   */
  static async save(data) {
    try {
      const response = await BaseAPI.post(`${URLS.ZIPCODERANGE}`, data);
      if (response.status === 201) return response;
      return { error: "Erro inexperado ao cadastrar faixa de cep" };
    } catch (e) {
      return this._exception(e, "Falha ao cadastrar faixa de cep");
    }
  }

  static async getListServices(params) {
    try {
      const response = await BaseAPI.get(`${URLS.ZIPCODERANGE}`, params);
      if (response.status === 200) return response.data;
      return { error: "Erro inexperado ao buscar lista de faixa de cep" };
    } catch (e) {
      return this._exception(e, "Falha ao buscar lista de faixa de cep");
    }
  }
}
export default ZipCodeRangeAPI;

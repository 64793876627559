class ChannelModel {
  
  constructor(data = {}) {
    if (!data) return;
    this.uuid = data.uuid;
    this.name = data.name;
    this.code = data.code;
    this.active = data.active;
  }
}

export default ChannelModel;

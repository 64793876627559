import { isInExibition } from "../helpers/utils/Functions";
import BaseModel from "./BaseModel";
import MerchantModel from "./MerchantModel";

export default class BannerModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;
    this.merchant = data.merchant && new MerchantModel(data.merchant);
    this.uuid = data.uuid;
    this.name_tag = data.nameTag;
    this.nameTag = data.nameTag;
    this.tag = data.tag;
    this.responsive = data.responsive;
    this.categoryBranch = data.categoryBranch;
    this.files = data.files || [];
    this.initialDate = data.initialDate || undefined;
    this.finalDate = data.finalDate || undefined;
    this.initialTime = data.initialTime || undefined;
    this.finalTime = data.finalTime || undefined;
    this.timePeriod = !isInExibition(data.initialDate, data.finalDate);
  }
}

import BaseAPI, { URLS } from './BaseAPI';

class UserAPI {
  /**Busca todos os admins */
  static async getUser(params) {
    try {
      const response = await BaseAPI.get(URLS.ACCOUNTS, params);
      if (response.status === 200) return response.data;
      return { error: 'Erro inexperado ao busca listas de usuarios' };
    } catch (e) {
      return { error: 'Falha ao buscar usuarios' };
    }
  }

  /** Busca usuarios por uuid */
  static async getUuidUser(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.ACCOUNTS}/${uuid}?by=uuid`);
      if (response.status === 200) return response.data;
      return { error: 'Erro inexperado ao busca usuario' };
    } catch (e) {
      return { error: 'Falha ao buscar usuario' };
    }
  }

  /**
   * @param  {string} uuid Uuid do usuário que será editado
   * @param {object} data dados do usuário que será editado
   */
  static async updateAdministrator(data, uuid, user_uuid) {
    try {
      // const response = await BaseAPI.put(`${URLS.ACCOUNTS}/${uuid}`, data);
      const response = await BaseAPI.put(`${URLS.ACCOUNTS}/${uuid}`, data);
      if (response.status === 200) return response.data;
      return { error: 'Falha ao atualizar informações do usuário' };
    } catch (e) {
      console.log(e);
      return { error: e.message };
    }
  }

  /**
   * @param  {Object} data Dados do novo usuário administrador
   */
  static async createAdministrator(data) {
    try {
      const response = await BaseAPI.post(URLS.ACCOUNTS, data);
      return response.status === 201
        ? response
        : { error: 'Falha ao cadastrar admin' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao cadastrar Administrador' };
    }
  }

  /**
   * @param  {string} uuid_user Uuid do usuário
   * @param {object} uuid_authoritie Uuid da authoritie que sera adicionada
   */
  static async addAuthoritie(uuid_authoritie, user_uuid) {
    try {
      const response = await BaseAPI.put(
        `/manager/authorities/${uuid_authoritie}/user/${user_uuid}`
      );
      return response.status === 201
        ? response
        : { error: 'Falha ao cadastrar autorização' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao cadastrar autorização' };
    }
  }

  /**
   * @param  {string} uuid_user Uuid do usuário
   * @param {object} uuid_authoritie Uuid da authoritie que sera adicionada
   */
  static async removeAuthoritie(uuid_authoritie, user_uuid) {
    try {
      const response = await BaseAPI.delete(
        `/manager/authorities/${uuid_authoritie}/user/${user_uuid}`
      );
      return response.status === 201
        ? response
        : { error: 'Falha ao remover autorização' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao remover autorização' };
    }
  }

  /**
   * @param  {string} uuid_user Uuid do usuário
   * @param {object} uuid_authoritie Uuid da authoritie que sera adicionada
   */
  static async addGroupAuthoritie(uuid_group, user_uuid) {
    try {
      const response = await BaseAPI.put(
        `/manager/authority-groups/${uuid_group}/user/${user_uuid}`
      );
      return response.status === 201
        ? response
        : { error: 'Falha ao cadastrar admin' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao cadastrar Administrador' };
    }
  }

  /**
   * @param  {string} uuid_user Uuid do usuário
   * @param {object} uuid_authoritie Uuid da authoritie que sera adicionada
   */
  static async deleteGroupAuthoritie(uuid_group, user_uuid) {
    try {
      const response = await BaseAPI.delete(
        `/manager/authority-groups/${uuid_group}/user/${user_uuid}`
      );
      return response.status === 201
        ? response
        : { error: 'Falha ao cadastrar admin' };
    } catch (e) {
      console.log(e.message);
      return { error: 'Falha ao cadastrar Administrador' };
    }
  }
}

export default UserAPI;

import axios from 'axios';
import StorageUtil, { KEYS } from '../helpers/utils/StorageUtil';
import { decodeJWT } from '../helpers/utils/Functions';
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";

/** Classe base que fará a comunicação com o backend.
 * Esta classe trata as configurações de headers, paths e todas as urls(endpoints) deverão ser inseridos aqui
 */
class BaseAPI {
  /** Métodos get */
  static async get(endpoint, data = {}) {
    const requestConfig = { params: data };
    return await api.get(endpoint, requestConfig);
  }

  /** Métodos post */
  static async post(endpoint, data = {}, config) {
    return await api.post(endpoint, data, config);
  }

  /** Método Put */
  static async put(endpoint, data = {}, config) {
    return await api.put(endpoint, data, config);
  }

  /** Método delete */
  static async delete(endpoint, data = {}) {
    return await api.delete(endpoint, { data });
  }

  /** Método Patch */
  static async patch(endpoint, data = {}) {
    return await api.patch(endpoint, data);
  }
}

/** URL de acordo ao protocolo, hostname e endpoint */
export const baseURL = () =>
  process.env.REACT_APP_API_URL || 'https://avenida-api.dev.stoomlab.com.br/';

// Cria uma instancia de api
const api = axios.create({ baseURL: baseURL(), withCredentials: true });
api.interceptors.request.use(
  (config) => {
    const token = StorageUtil.getItem(KEYS.TOKEN_KEY);
    config.headers = {
      'Content-Type': 'application/json; charset=utf-8',
      accept: '*/*',
    };
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    if (config?.code?.headers?.code) {
      config.headers = { '2fa': config?.code?.headers?.code };
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// Intercepta os retornos.
// TODO fazer a lógica do refresh token.
api.interceptors.response.use(
  (response) => {
    const token = response.headers.authorization;
    if (token) {
      const currentToken = response.headers.authorization.split(' ')[1];
      const decode = decodeJWT(currentToken);
      StorageUtil.setItem(KEYS.AUTH_KEY, decode.Authorities);
      StorageUtil.setItem(KEYS.TOKEN_KEY, currentToken);
    }
    return response;
  },
  (error) => {
    const toastHelper = new ToastHelper();
    if (error.response.status === 401) {
      toastHelper.notify(
        STATUS_HELPER.ERROR,
        "Sessão expirada, por favor, faça login novamente"
      );
      setTimeout(() => {
        StorageUtil.cleanAll();
        window.location.href = '/logout';
      }, 2000);
    }
    return Promise.reject(error);
  },
);

// Lista de todos os endpoints. Todos endpoints deverão ser inseridos aqui.
const MANAGER = '/manager';
export const URLS = {
  ACCOUNTS: `${MANAGER}/accounts`,
  APPLICATION: `${MANAGER}/applications`,
  AUTHORITY_GROUPS: `${MANAGER}/authority-groups`,
  AUTHORITY: `${MANAGER}/authorities`,
  AUTOMAKER: `${MANAGER}/automakers`,
  BANNER: `${MANAGER}/banners`,
  BANNERS: `${MANAGER}/banners`,
  BRANCHES: `${MANAGER}/branches`,
  BRANDS: `${MANAGER}/brands`,
  CAMPAIGNS: `${MANAGER}/campaigns`,
  CATEGORIES: `${MANAGER}/categories`,
  CLASSES: `${MANAGER}/properties/classes`,
  COLORS: `${MANAGER}/colors`,
  COUPONS_SEARCH: `${MANAGER}/promotion/search`,
  COUPONS: `${MANAGER}/voucherBatches`,
  CUSTOMER_GROUP: `${MANAGER}/customergroups`,
  CUSTOMERS: `${MANAGER}/customers`,
  DYNAMIC_HOME: `${MANAGER}/components`,
  FILES: '/files',
  FORGET_PASSWORD: '/forget-password',
  INSTITUTIONAL: `${MANAGER}/institutional`,
  INSUMOS_HOLAMBRA: `${MANAGER}/insumosHolambra-customers`,
  LOGIN: '/login',
  MANAGER,
  MERCHANT: `${MANAGER}/merchants`,
  NEWS_LETTER: `${MANAGER}/newsletters`,
  ORDER: `${MANAGER}/orders`,
  PRE_ORDERS: `${MANAGER}/preorders`,
  PRODUCTS: `${MANAGER}/products`,
  PROPERTIES: `${MANAGER}/properties/classes`,
  PROPERTY: `${MANAGER}/properties`,
  SALES_DETAILS: `${MANAGER}/reports/sales-details`,
  SALES: `${MANAGER}/reports/sales-general`,
  SEARCHORDERS: `${MANAGER}/orders`,
  SHIPPING: `${MANAGER}/shipping`,
  SIZES: `${MANAGER}/sizes`,
  STORES: `${MANAGER}/stores`,
  SUPPLIER: `${MANAGER}/suppliers`,
  UNITS: `${MANAGER}/units`,
  ZIPCODERANGE: `${MANAGER}/zipcode-range`,
};

export default BaseAPI;

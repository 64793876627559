import CustomerType from './CustomerTypeModel';
import BaseModel from './BaseModel';

export default class PriceModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.price = data.price;
    this.customerType = new CustomerType(data.customerType);
  }
}

class ColorModel {
  constructor(data = {}) {
    if (!data) return;
    this.uuid = data.uuid;
    this.externalId = data.externalId;
    this.name = data.name;
    this.shortName = data.shortName;
    this.hexColorCode = data.hexColorCode;
  }
}

export default ColorModel;

import { extendObservable } from 'mobx';
import { entityTypes } from '~/helpers/utils/Selects';
import AddressModel from './AddressModel';
import BaseModel from './BaseModel';
import FileModel from './FileModel';
import CustomerModel from './CustomerModel';
import MerchantModel from './MerchantModel';
import OrderItemModel from './OrderItemModel';
import OrderStatusModel, { ORDER_STATUS } from './OrderStatusModel';
import PaymentDetailModel from './PaymentDetailModel';
import StatusChangesModel from './StatusChangesModel';
import ShippingDetailsModel from './ShippingDetailsModel';

class OrderModel extends BaseModel {
  constructor(data = {}) {
    super(data);

    this.status_statusName = data.status_statusName;
    this.customer_firstName = data.customer_firstName;
    this.customer_uuid = data.customer_uuid;
    this.customer_document = data.customer_document;
    this.status_statusName = data.status_statusName;
    this.shippingDetails =
      data.shippingDetails && new ShippingDetailsModel(data.shippingDetails);
    this.idOrder = data.idOrder;
    this.idOrderMerchant = data.idOrderMerchant;
    this.externalId = data.externalId;
    this.uuid = data.uuid;
    this.merchant_name = data.merchant_name;
    this.orderNumber = data.orderNumber;
    this.productsValue = data.productsValue;
    this.productsDiscounts = data.productsDiscounts;
    this.productsTotal = data.productsTotal;
    this.shippingService = data.shippingService;
    this.shippingValue = data.shippingValue;
    this.shippingDiscounts = data.shippingDiscounts;
    this.shippingTotal = data.shippingTotal;
    this.paymentDiscounts = data.paymentDiscounts;
    this.campaignDiscounts = data.campaignDiscounts;
    this.totalDiscounts = data.totalDiscounts;
    this.totalValue = data.totalValue;
    this.zipCode = data.zipCode;
    this.total = data.total;
    this.paymentGatewayCode = data.paymentGatewayCode;
    this.merchant = data.merchant && new MerchantModel(data.merchant);
    this.shippingTrackingCode = data.shippingTrackingCode;
    this.refundInvoice = data.refundInvoice;
    this.billet = data.billet;
    this.billetExpiration = data.billetExpiration;
    this.orderItems =
      data.orderItems &&
      data.orderItems.map((item) => new OrderItemModel(item));
    this.statusChanges =
      data.statusChanges &&
      data.statusChanges.map((status) => new StatusChangesModel(status)); // TODO FAZER O MAP)
    this.customer = data.customer
      ? new CustomerModel(data.customer)
      : undefined;
    this.files = data.files && data.files.map((file) => new FileModel(file));
    this.shippingAddress =
      data.shippingAddress && new AddressModel(data.shippingAddress);
    this.importer = data.importer;
    this.observation = data.observation;
    this.icmsst = data.icmsst;
    this.paymentDetails =
      data.paymentDetail && new PaymentDetailModel(data.paymentDetail);
    extendObservable(this, {
      status: data.status && new OrderStatusModel(data.status),
    });
    this.omniVoucherDiscounts = data.omniVoucherDiscounts;
  }

  //Retorna um array com o arquivo do cliente, caso exista.
  get fileOrder() {
    return this.files.find(
      (file) => file.path && file.metaTags.some((tag) => tag === 'client-order')
    );
  }

  /**Retorna nome do cliente */
  get clientName() {
    if (!this.customer) return '';
    return this.entity === entityTypes[0].value
      ? this.customer.fullName
      : this.customer.firstName;
  }

  //Status atual (descrição)
  get statusName() {
    return this.status;
  }

  //Quantidade de items do pedido
  get countItems() {
    return this.orderItems.length;
  }
}

export default OrderModel;

import BaseModel from "./BaseModel";
import MerchantModel from "./MerchantModel";
import FileModel from "./FileModel";
class BranchModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;
    this.merchant = data.merchant && new MerchantModel(data.merchant);
    this.externalId = data.externalId ? data.externalId : new Date().getTime();
    this.uuid = data.uuid;
    this.name = data.name;
    this.document = data.document;
    this.email = data.email;
    this.addressComplement = data.addressComplement;
    this.block = data.block;
    this.city = data.city;
    this.phone = data.phone;
    this.state = data.state;
    this.street = data.street;
    this.zipcode = data.zipcode;
    this.number = data.number;
    this.lat = data.lat;
    this.lng = data.lng;
    this.startHourMonFri = data.startHourMonFri;
    this.endHourMonFri = data.endHourMonFri;
    this.startHourSat = data.startHourSat || null;
    this.endHourSat = data.endHourSat || null;
    this.startHourSun = data.startHourSun || null;
    this.endHourSun = data.endHourSun || null;
    this.startHourHol = data.startHourHol || null;
    this.endHourHol = data.endHourHol || null;
    this.image = new FileModel(data.image);
    this.ordering = data.ordering;
    this.isCategory = data.isCategory;
    this.description = data.description;
    this.isVisibleOnNavBar = data.isVisibleOnNavBar;
    this.googleMyBusinessUrl = data.googleMyBusinessUrl
  }
}

export default BranchModel;

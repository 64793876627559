import { convertPrice } from "~/helpers/utils/Functions";
import BaseModel from "./BaseModel";
import CategoryModel from "./CategoryModel";
import FileModel from "./FileModel";
// import ItemsCombo from './ItemsCombo';
import MerchantModel from "./MerchantModel";
import PriceModel from "./PriceModel";
import ProductPropertyModel from "./ProductPropertyModel";
import StockModel from "./StockModel";
import SupplierModel from "./SupplierModel";
import ColorModel from "./ColorModel";
import SizeModel from "./SizeModel";

export default class ProductModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.parent = data.parent;
    this.skuCode = data.skuCode;
    this.barcode = data.barcode;
    this.name = data.name;
    this.shortName = data.shortName;
    this.description = data.description;
    this.shortDescription = data.shortDescription;
    this.metaTitle = data.metaTitle;
    this.metaKeywords = data.metaKeywords;
    this.metaDescription = data.metaDescription;
    this.metaCanonical = data.metaCanonical;
    this.slug = data.slug;
    this.sendStockEmail = data.sendStockEmail;
    this.quantityStockEmail = data.quantityStockEmail;
    this.redirect = data.redirect;
    this.display = data.display !== undefined ? data.display : true;
    this.searchable = data.searchable !== undefined ? data.searchable : true;
    this.supplier = data.supplier
      ? new SupplierModel(data.supplier)
      : undefined;
    this.supplierNumber = data.supplierNumber;
    this.currency = data.currency ? data.currency : "BRL";
    this.fakePrice = data.fakePrice;
    this.absoluteDiscount = data.absoluteDiscount;
    this.percentualDiscount = data.percentualDiscount;
    this.discountBeginDate =
      data.discountBeginDate && new Date(data.discountBeginDate);
    this.discountEndDate =
      data.discountEndDate && new Date(data.discountEndDate);
    this.releaseDate = data.releaseDate && new Date(data.releaseDate);
    this.weight = data.weight;
    this.height = data.height;
    this.width = data.width;
    this.length = data.length;
    this.shippingWeight = data.shippingWeight;
    this.shippingHeight = data.shippingHeight;
    this.shippingWidth = data.shippingWidth;
    this.shippingLength = data.shippingLength;
    this.stocks = data.stocks
      ? data.stocks.map((props) => new StockModel(props))
      : [];
    this.prices = data.prices
      ? data.prices.map((props) => new PriceModel(props))
      : [];

    this.color = data.color ? new ColorModel(data.color) : null;
    this.size = data.size ? new SizeModel(data.size) : null;
    this.committedStock = data.committedStock;
    this.securityStock = data.securityStock;
    this.deliveryTime = data.deliveryTime;
    this.stockUnit = data.stockUnit;
    this.merchant = new MerchantModel(data.merchant);
    this.productVariations = data.productVariations
      ? data.productVariations.map((prop) => new ProductModel(prop))
      : [];
    this.productProperties = data.productProperties
      ? data.productProperties.map((prop) => new ProductPropertyModel(prop))
      : [];

    // this.itemsCombo = data.itemsCombo
    //   ? data.itemsCombo.map((prop) => new ItemsCombo(prop))
    //   : [];

    this.baseProduct = data.baseProduct;

    this.files = data.files
      ? data.files.map((file) => new FileModel(file))
      : [];

    /*this.files = data.files
      ? data.files.reduce((list, file) => {
        if (!list.some((lFile) => lFile.metaTags[0] === file.metaTags[0])) {
          list.push(new FileModel(file));
        }
        return list;
      }, [])
      : [];*/

    this.categories = data.categories
      ? data.categories.map((cat) => new CategoryModel(cat))
      : [];
  }

  get supplierName() {
    return this.supplier ? this.supplier.name : "";
  }

  getFile(tag) {
    return this.files.find(
      (file) => file.path && file.metaTags.some((metaTag) => metaTag === tag)
    );
  }

  getFiles(tag) {
    return this.files.filter(
      (file) => file.path && file.metaTags.some((metaTag) => metaTag === tag)
    );
  }

  getFirstFile() {
    return this.files?.length > 0 ? this.files[0] : null;
  }

  getOthersFiles() {
    return this.files?.length > 0 ? this.files.slice(1) : null;
  }

  /** Preço formatado em string R$99999,9999 */
  get fakePriceString() {
    if (this.fakePrice) return convertPrice(this.fakePrice);
    return undefined;
  }

  /** Desconto absoluto formatado em string R$999999,99 */
  get absoluteDiscountString() {
    if (this.absoluteDiscount) return convertPrice(this.absoluteDiscount);
    return "";
  }

  /** Add novo supplier */
  setSupplier(supplier) {
    this.supplier = new SupplierModel(supplier);
  }

  get hasFiles() {
    return this.files.length > 0;
  }
}

import BaseModel from './BaseModel';

export default class ShippingDetailsModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this['@class'] = 'br.com.stoom.kernel.model.persistence.PaymentDetails';
    this.shippingPrice = data.shippingPrice; //com desconto
    this.shippingTotal = data.shippingTotal; //  sem desconto
    this.shippingType = data.shippingType;
  }
}

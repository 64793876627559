import { extendObservable } from 'mobx';
import SizeAPI from '../services/SizeAPI';
import ToastHelper, { STATUS_HELPER } from '~/helpers/ToastHelper';
import SizeModel from '~/models/SizeModel';

class SizeStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, {
      sizes: [],
      sizesListSelect: [],
      staffs: [],
      loading: false,
      superAdmin: false,
      totalPages: 0,
      size: 15,
      page: 0,
      sort: 'code',
    });
  }

  reset() {
    this.size = undefined;
  }

  /**Atualiza uma propriedade do novo adminstrator*/
  async onChange(prop, value) {
    const _size = this.size ? this.size : new SizeModel();
    switch (prop) {
      default:
        _size[prop] = value;
    }
    this.size = new SizeModel(_size);
  }

  /**Cria um novo usuário adminin */
  async onCreate() {
    this.loading = true;
    const data = JSON.stringify(this.size);
    const response = await SizeAPI.save(data);
    this.loading = false;
    if (!response.error) {
      this.size = new SizeModel();
      this.toastHelper.notify(STATUS_HELPER.INFO, 'Cor criada com sucesso.');
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPage(numPage, size = 15, sort) {
    this.page = numPage;
    this.size = size;
    this.sort = 'code';

    sort ? (this.sort = sort) : (this.sort = 'code');

    await this.getList();
    return true;
  }

  /**Busca todos os usuarios addmins */
  async getList(size = 15, page = this.page, sort = this.sort) {
    this.loading = true;
    const response = await SizeAPI.getList({
      size,
      page,
      sort,
    });
    this.loading = false;
    if (response.error) return [];
    this.sizes = response.content.map((usr) => new SizeModel(usr));
    this.totalPages = response.totalPages;
    this.page = response.number;
  }

  /**
   * Busca todas as informações de um usuario administrador.
   * @param  {string} userName
   */
  async get(userName) {
    this.loading = true;
    const response = await SizeAPI.get(userName);
    this.loading = false;
    if (!response.error) {
      this.size = new SizeModel(response);
      return this.size;
    }
    this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**Busca um usuário customer por um parametro. */
  async onUpdate() {
    this.loading = true;

    const data = JSON.stringify(this.size);
    const response = await SizeAPI.update(this.size.uuid, data);
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        'Informações atualizadas com sucesso!'
      );
      this.size = new SizeModel(response);
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  /**Deleta administrador */
  async delete(uuid) {
    this.loading = true;
    const response = await SizeAPI.delete(uuid);
    if (!response.error) {
      this.toastHelper.notify(STATUS_HELPER.INFO, 'Cor excluído com sucesso!');
      this.getList();
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  async getSizeListSelect() {
    const response = await SizeAPI.getList({
      size: 100,
      page: 0,
      sort: 'code',
    });

    if (response.error) {
      this.sizesListSelect = [];
      return;
    }

    this.sizesListSelect = response.content.map((size) => ({
      value: size.uuid,
      label: size.code,
    }));
  }

  async getExpoxtList(
    size = 1000,
    page = this.page,
    sort = this.sort,
    filters = this.filters
  ) {
    const response = await SizeAPI.getList({
      size,
      page,
      sort,
      ...filters,
    });

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loading = false;
      return [];
    }
    return response.content.map((usr) => new SizeModel(usr));
  }
}
export default SizeStore;

import { extendObservable } from 'mobx';
import ToastHelper, { STATUS_HELPER } from '~/helpers/ToastHelper';
import CampaignModel from '~/models/CampaignModel';
import CampaignAPI from '~/services/CampaignAPI';
import CustomerAPI from '../services/CustomerAPI';
import { isArrayEmpty, onlyUnique } from '~/helpers/utils/Functions';
import CampaignModelsEnum from '~/helpers/utils/enums/CampaignModelsEnum';
import {
  DateRestriction,
  PriceRestriction,
  ProductCategoryRestriction,
  CustomerGroupRestriction,
  CustomerRestriction,
  PointOfSaleRestriction,
  ZipCodeRestriction,
  SubChannelRestriction,
} from '~/models/RestrictionModel';

const campaignItems = [
  {
    label: CampaignModelsEnum.DESCONTO_PERCENTUAL,
    value: CampaignModelsEnum.DESCONTO_PERCENTUAL,
  },
  {
    label: CampaignModelsEnum.FRETE_FIXO,
    value: CampaignModelsEnum.FRETE_FIXO,
  },
];

const initValues = {
  loading: true,
  totalPages: 0,
  page: 0,
  size: 10,
  typeCampaign: '',
  isAllProducts: false,
  campaign: new CampaignModel({ active: false }),
  infiniteCampaign: false,
  clients: false,
  priceRestriction: new PriceRestriction(),
  dateRestriction: new DateRestriction(),
  productCategoryRestriction: new ProductCategoryRestriction(),
  pointOfSaleRestriction: new PointOfSaleRestriction(),
  customerRestriction: new CustomerRestriction(),
  customerGroupRestriction: new CustomerGroupRestriction(),
  zipCodeRestriction: new ZipCodeRestriction(),
  subChannelRestriction: new SubChannelRestriction(),
  zipCodeRestrictions: [],
  campaigns: [],
  documents: [],
  campaignItems: campaignItems,
};

class CampaignStore {
  totalPages = 0;
  page = 0;
  size = 10;
  sort = 'created,desc';

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, { ...initValues });
  }

  async setPage(numPage, size = 10, useLogin = true, sort) {
    this.page = numPage;
    this.size = size;
    sort && (this.sort = sort);
    await this.getList();
    return this.totalPages;
  }

  reset() {
    this.campaign = new CampaignModel();
    this.dateRestriction = new DateRestriction();
    this.campaigns = [];
    this.totalPages = 0;
    this.size = 10;
    this.page = 0;
    this.loading = true;
    this.applicationType = undefined;
    this.typeCampaign = '';
    this.documents = [];
  }

  /**Busca lista de campanhas */
  async getList(
    size = 10,
    page = this.page,
    sort = this.sort,
    filters = this.filters
  ) {
    this.loading = true;
    const response = await CampaignAPI.getList({
      size,
      page,
      sort,
      ...filters,
    });

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      return [];
    }
    this.loading = false;
    this.campaigns = response.content;
    this.totalPages = response.totalPages;
    this.page = response.number;
  }

  async getExpoxtList(
    size = 1000,
    page = this.page,
    sort = this.sort,
    filters = this.filters
  ) {
    const response = await CampaignAPI.getList({
      size,
      page,
      sort,
      ...filters,
    });

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loading = false;
      return [];
    }
    return response.content.map((usr) => new CampaignModel(usr));
  }

  async getCustomer(filters) {
    const response = await CustomerAPI.getList({
      size: 10,
      page: 0,
      ...filters,
    });

    if (!response.error) {
      if (response.content && response.content.length > 0) {
        return response.content[0];
      }
      return [];
    }
    return [];
  }

  handleSearchDocument = (documents) => {
    documents.map(async (item) => {
      let data;

      let search = '';
      if (item !== '' && item !== undefined) {
        search += `,document;${item}`;
      }

      data = await this.getCustomer({ search });
      this.documents.push({
        uuid: data.uuid,
        firstName: data.firstName,
        active: data.active,
        document: data.document,
      });
    });
    this.loading = false;
  };

  setValueChannel(uuid) {
    if (uuid !== undefined) {
      this.subChannelRestriction.channels.push({ uuid: uuid });
      this.subChannelRestriction.subChannels = [];
    }
  }

  setValueSubChannel(uuid) {
    if (uuid !== undefined) {
      this.subChannelRestriction.subChannels.push({ uuid: uuid });
      this.subChannelRestriction.channels = [];
    }
  }
  /**Função para ativar/inativar campanha no grid */
  async activeCampaign(campaign) {
    const response = await CampaignAPI.switchActiveFlag(campaign.uuid);

    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        `Campanha ${campaign.active ? 'ativada' : 'inativada'} com sucesso`
      );
      this.getList();
      return true;
    }
    this.toastHelper.notify(
      STATUS_HELPER.ERROR,
      `Erro ao ${campaign.active ? 'ativar' : 'inativar'} a campanha`
    );
    return false;
  }

  setValueShippingRange(value) {
    if (value !== undefined) {
      const zipCode = this.zipCodeRestrictions;
      zipCode.map((item) => {
        item.excludes = value;
      });
      this.zipCodeRestrictions = zipCode;
    }
  }

  async getCampaignByUuid(campaignUuid) {
    this.loading = true;

    const response = await CampaignAPI.getCampaignByUuid(campaignUuid);
    if (!response.error) {
      this.campaign = new CampaignModel(response);
      if (!!response?.discounts[0]?.buyXPayY?.x) {
        this.buyXPayY.x = response.discounts[0].buyXPayY.x;
        this.buyXPayY.y = response.discounts[0].buyXPayY.y;
      }
      this.typeCampaign = response?.type;

      if (response?.restrictions && response?.restrictions?.length > 0) {
        this.organizeRestrictions(response?.restrictions);
        let zipCodeTemp = [];

        // let temp = response.restrictions?.map((restriction) => {
        //   if (restriction["@class"].includes("ZipCodeRestriction")) {
        //     zipCodeTemp.push(restriction);
        //   }
        // });

        this.zipCodeRestrictions = zipCodeTemp;
        const productsRestriction = response.restrictions?.map((restriction) =>
          restriction['@class'].includes('ProductCategoryRestriction')
        );
        if (productsRestriction && productsRestriction?.products?.length > 0) {
          this.productCategoryRestriction.products = [
            ...productsRestriction.products,
          ];
        }
      }
      // this.loading = false;
      return this.campaign;
    }
    this.loading = false;
    return false;
  }

  /**Função para entrar nas restricoes e direcionar para o objetos */
  async organizeRestrictions(restrictions) {
    restrictions.map((r) => {
      switch (r['@class']) {
        case 'br.com.stoom.kernel.model.persistence.restriction.ProductCategoryRestriction':
          this.productCategoryRestriction = r;
          break;
        case 'br.com.stoom.kernel.model.persistence.restriction.DateRestriction':
          if (r.maxDate.includes('2121')) {
            this.infiniteCampaign = true;
            this.dateRestriction.minDate = r.minDate;
            this.dateRestriction.maxDate = undefined;
          } else {
            this.infiniteCampaign = false;
            this.dateRestriction.minDate = r.minDate;
            this.dateRestriction.maxDate = r.maxDate;
          }
          break;
        case 'br.com.stoom.kernel.model.persistence.restriction.PriceRestriction':
          this.priceRestriction = r;
          break;
        case 'br.com.stoom.kernel.model.persistence.restriction.GroupRestriction':
          this.customerGroupRestriction = r;
          break;
        case 'br.com.stoom.kernel.model.persistence.restriction.CustomerRestriction':
          this.clients = r.excludes;

          if (r.documents.length > 0) {
            this.handleSearchDocument(r.documents);
          }

          break;
        case 'br.com.stoom.kernel.model.persistence.restriction.ZipCodeRestriction':
          this.shippingRange = r?.excludes;
          break;
        case 'br.com.stoom.tresCoracoes.model.TresCoracoesChannelRestriction':
          this.subChannelRestriction = r;
          break;
        default:
          break;
      }
    });
  }

  selectedModelCampaign(modelSelected) {
    this.productCategoryRestriction = new ProductCategoryRestriction();
    this.typeCampaign = modelSelected;
    this.isAllProducts = false;
    this.handleCampaignChange('type', modelSelected);
  }

  checkedAllProducts() {
    this.isAllProducts = true;
    this.productCategoryRestriction.categories = [];
    this.productCategoryRestriction.products = undefined;
  }

  async setFilters(filters) {
    this.filters = filters;
    await this.getList(this.size, this.page, this.sort, filters);
  }

  setValueClients(value) {
    if (value !== undefined) {
      const customer = this.customerRestriction;
      customer.excludes = value;
      this.customerRestriction = customer;
    }
  }

  handleCampaignChange(prop, value) {
    switch (prop) {
      case 'excludes':
        this.productCategoryRestriction[prop] = value;
        break;
      default:
    }

    const { campaign } = this;
    campaign[prop] = value;
    this.campaign = new CampaignModel(campaign);
  }

  checkedInfiniteCampaign(checked) {
    this.infiniteCampaign = checked;
    if (checked) {
      this.dateRestriction.maxDate = undefined;
    }
  }

  handleChangeDate(prop, value) {
    if (prop === 'maxDate') {
      this.dateRestriction.maxDate = value;
    } else {
      this.dateRestriction.minDate = value;
    }
  }

  handleChangeDiscount(value) {
    const updatedCampaign = { ...this.campaign };
    updatedCampaign.discounts[0].productsPercentageDiscount =
      value.length > 0 ? value / 100 : 0;
    updatedCampaign.fixedPrice.fixedShippingPrice = 0;
    this.campaign = new CampaignModel(updatedCampaign);
  }

  handleChangeFixedPrice(value) {
    const updatedCampaign = { ...this.campaign };
    if (typeof value !== 'number') value = value.replace(',', '.');
    updatedCampaign.fixedPrice.fixedShippingPrice = value;
    updatedCampaign.discounts[0].productsPercentageDiscount = 0;
    this.campaign = new CampaignModel(updatedCampaign);
  }

  /**Salva uma nova campanha */
  async onSave() {
    this.loading = true;
    const { campaign } = this;
    if (this.infiniteCampaign) {
      this.dateRestriction.maxDate = '2121-05-03T00:00:00.000Z';
    }

    const restrictions = this.setRestrictionsInBody(this.campaign.type);
    campaign.restrictions = [...restrictions];

    if (this.zipCodeRestrictions.length > 0) {
      campaign.restrictions.push(...this.zipCodeRestrictions);
    }

    campaign.restrictions.push(this.subChannelRestriction);
    if (this.documents.length > 0) {
      let arrayDocuments = [];
      this.documents.map((item) => {
        arrayDocuments.push(item.document);
      });
      this.customerRestriction.documents.push(arrayDocuments);
      campaign.restrictions.push(this.customerRestriction);
    }
    const response = await CampaignAPI.save(campaign);
    this.loading = false;

    if (!response.error) {
      this.campaign = response.data;
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        'Campanha adicionada com sucesso!'
      );
      this.reset();
      return true;
    }

    this.toastHelper.notify(
      STATUS_HELPER.ERROR,
      'Erro ao adicionar a campanha'
    );
    return false;
  }

  /**Funcao para atualizar a campanha */
  async onUpdate() {
    this.loading = true;

    if (this.infiniteCampaign) {
      this.dateRestriction.maxDate = '2121-05-03T00:00:00.000Z';
    }

    const restrictions = this.setRestrictionsInBody(this.campaign.type);
    const { campaign } = this;

    campaign.restrictions = restrictions;

    if (this.customerGroupRestriction.customerGroups.length > 0) {
      campaign.restrictions.push(this.customerGroupRestriction);
    }

    if (this.zipCodeRestrictions.length > 0) {
      campaign.restrictions.push(...this.zipCodeRestrictions);
    }

    campaign.restrictions.push(this.subChannelRestriction);

    if (this.documents.length > 0) {
      let arrayDocuments = [];
      this.documents.map((item) => {
        arrayDocuments.push(item.document);
      });
      this.customerRestriction.documents = arrayDocuments;
      campaign.restrictions.push(this.customerRestriction);
    }

    const response = await CampaignAPI.update(campaign, campaign.uuid);

    this.loading = false;

    if (!response.error) {
      this.reset();

      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        'Campanha atualizada com sucesso!'
      );

      return true;
    }

    this.toastHelper.notify(
      STATUS_HELPER.ERROR,
      'Erro ao atualizar a campanha'
    );
    return false;
  }

  /**Seta as restricoes de acordo com a campanha para usar no post/put */
  setRestrictionsInBody(type) {
    switch (type) {
      case CampaignModelsEnum.DESCONTO_PERCENTUAL:
        this.campaign.bean = 'Discount_Campaign';

        if (this.isAllProducts) {
          if (this.avenidaPointOfSaleRestriction?.pointsOfSale?.length > 0) {
            return [this.dateRestriction, this.avenidaPointOfSaleRestriction];
          }
          return [this.dateRestriction];
        } else {
          if (this.avenidaPointOfSaleRestriction?.pointsOfSale?.length > 0) {
            return [
              this.dateRestriction,
              this.productCategoryRestriction,
              this.avenidaPointOfSaleRestriction,
            ];
          }
          return [this.dateRestriction, this.productCategoryRestriction];
        }

      default:
        return [this.dateRestriction];
    }
  }

  async handleAddZipCode(data) {
    const zipCode = new ZipCodeRestriction();
    zipCode.initialZipCode = data.initialZipCode;
    zipCode.finalZipCode = data.finalZipCode;

    const updateDocuments = this.zipCodeRestrictions;
    updateDocuments.push(zipCode);
  }

  /**Adiciona o produto na lista de restrição */
  async handleAddGroup(data) {
    if (this.customerGroupRestriction.customerGroups === undefined) {
      this.customerGroupRestriction.customerGroups = [];
    }

    if (data !== undefined) {
      this.customerGroupRestriction.customerGroups.push(data);
    }
  }

  handleDeleteGroup(data) {
    const filter = this.customerGroupRestriction.customerGroups.filter(
      (item) => item.value !== data.value
    );
    this.customerGroupRestriction.customerGroups = filter;
  }

  handleDeleteZipCode(data) {
    const filter = this.zipCodeRestrictions.filter(
      (item) => item.uuid !== data.uuid
    );
    this.zipCodeRestrictions = filter;
  }

  applicationTypeVerify() {
    let applicationType = false;

    if (
      this.productCategoryRestriction.categories &&
      this?.productCategoryRestriction?.categories?.length > 0
    ) {
      this.productCategoryRestriction.categories[0].type === 'VITRINE' &&
        (applicationType = 'CATEGORIA');
    } else if (
      this.productCategoryRestriction.products &&
      this.productCategoryRestriction.products.length > 0
    ) {
      applicationType = 'PRODUTO';
    }

    return applicationType;
  }

  setCategory(categories) {
    const selected = categories.map((category) => {
      return {
        uuid: category.uuid,
        categoryName: category.categoryName,
        '@class': category['@class'],
      };
    });

    this.categoriesRemove = this.productCategoryRestriction.categories.filter(
      (cat) => !selected.some((mCat) => mCat.uuid === cat.uuid)
    );

    this.categoriesAdd = selected.filter(
      (cat) =>
        !this.productCategoryRestriction.categories.some(
          (mCat) => mCat.uuid === cat.uuid
        )
    );

    this.productCategoryRestriction.categories = [
      ...this.productCategoryRestriction.categories,
      ...this.categoriesAdd,
    ]
      .filter((f) => this.categoriesRemove.indexOf(f) < 0)
      .filter(onlyUnique);
    this.productCategoryRestriction.products = [];

    return [
      ...this.productCategoryRestriction.categories,
      ...this.categoriesAdd,
    ]
      .filter((f) => this.categoriesRemove.indexOf(f) < 0)
      .filter(onlyUnique);
  }

  removeCategory() {
    this.productCategoryRestriction.categories = [];
    this.productCategoryRestriction.products = [];
  }

  async deleteCampaign(campaignUuid) {
    const response = await CampaignAPI.delete(campaignUuid);

    if (!response.error) {
      await this.getList();
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        'Campanha deletada com sucesso'
      );
      return true;
    }
    this.toastHelper.notify(STATUS_HELPER.ERROR, 'Falha ao deletar a campanha');
  }

  addProduct(product) {
    if (isArrayEmpty(this.productCategoryRestriction.products)) {
      this.productCategoryRestriction.products = [];
    }

    this.productCategoryRestriction.products.push(product);
    this.productCategoryRestriction.categories = [];
  }

  async addDocument(data) {
    this.documents.push(data);
  }

  removeProduct(product) {
    if (isArrayEmpty(this.productCategoryRestriction.products)) {
      return;
    }

    this.productCategoryRestriction.products =
      this.productCategoryRestriction.products.filter(
        (f) => f.uuid !== product.uuid
      );
    this.productCategoryRestriction.categories = [];
    // this.renderAttribute = !this.renderAttribute;
  }

  removeDocument(item) {
    const docIndex = this.documents.findIndex(
      (value) => value.uuid === item.uuid
    );

    const filter = this.documents.filter((_, index) => index !== docIndex);

    this.documents = filter;
  }
}

export default CampaignStore;

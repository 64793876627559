class UnitModel {
  constructor(data = {}) {
    if (!data) return;
    this.uuid = data.uuid;
    this.externalId = data.externalId;
    this.code = data.code;
    this.description = data.description;
  }
}

export default UnitModel;
